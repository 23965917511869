import axios from 'axios'
import Cookies from 'js-cookie'
// import router from '@/router'

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000, 
    headers: {  'Access-Control-Allow-Origin': '*', 
                'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE', 
                'Cache-Control': 'no-cache, no-store, must-revalidate' , 
                'Pragma': 'no-cache',
                'Expires': '0', 
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json'
            },
})

// request interceptor
instance.interceptors.request.use(
    config => {
        // console.log(config)
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(response => {
    return response.data
}, error => {
    var error_message = 'Error: '
    if (error.response && error.response.status && (error.response.status == 401 || error.response.status == 400)) {
        Cookies.remove('token')
        error_message += error.response.status + ' - ' + error.response.data.message
        window.location.replace(process.env.VUE_APP_ASP_MANAGE_PAGE_DOMAIN + "/manage/mypage")
    }else if (error.request) {
        error_message += error.message
    }else{
        error_message += error.message
    }
    return Promise.reject(error_message)
})

export default instance
