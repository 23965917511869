<template>
  <div>
    <div class="modal modal-setting modal-button-setting" id="buttonSettingModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="buttonSettingModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
        <div class="modal-content builder-modal-content modal-content-button-setting">
          <div class="modal-header" style="background-color:#FFFFFF;">
            <div class="w-100 d-flex flex-row justify-content-between">
              <button type="button" class="btn btn-back d-flex align-items-center" data-bs-dismiss="modal">
                <span v-html="icon_svg.bi_chevron_left()"></span>
              </button>
              <div class="title fs-5 d-flex align-items-center" id="buttonSettingModalLabel">{{$t('button_setting')}}</div>
              <div style="min-width: 2rem;"></div>
            </div>
          </div>
          <div class="modal-body p-0 pb-5" style="background-color:#FFFFFF; overflow-y: scroll;">
            <div class="preview sticky-top" style="z-index:1;">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <button type="button" :style="preview_style">
                  <i class="bi bi-gear-wide-connected me-2"></i> {{$t('button')}}
                </button>
              </div>
            </div>
            <div class="p-3">
              <div class="fw-bold fs-6">{{$t('button')}}</div>
              <div class="mt-2 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('border_width')}}</div>
                <div class="text-primary fw-semibold">{{border_width}} px</div>
              </div>
              <input type="range" class="form-range" min="0" max="6" step="1" v-model="border_width" id="button_border_width" @change="updatePreview()">
              
              <div class="mt-3 fw-normal fs-6">{{$t('border_color')}}</div>
              <ColorPicker :value="border_color" @change="changeBorderColor" key="button_border_color"></ColorPicker>
              <div class="mt-3 fw-normal fs-6">{{$t('background_color')}}</div>
              <ColorPicker :value="background_color" @change="changeBackgroundColor" key="button_background_color"></ColorPicker>

              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('border_radius')}}</div>
                <div class="text-primary fw-semibold">{{border_radius}} px</div>
              </div>
              <input type="range" class="form-range" min="0" max="48" step="2" v-model="border_radius" id="button_border_radius" @change="updatePreview()">

              <div class="mt-3 fw-normal fs-6">{{$t('box_shadow')}}</div>
              <div class="mt-2">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="box_shadow_type" v-model="box_shadow_type" id="box_shadow_type_1" value="box_shadow_1" @change="updatePreview()">
                  <label class="form-check-label" for="box_shadow_type_1">
                    {{$t('box_shadow_none')}}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="box_shadow_type" v-model="box_shadow_type" id="box_shadow_type_2" value="box_shadow_2" @change="updatePreview()">
                  <label class="form-check-label" for="box_shadow_type_2">
                    {{$t('box_shadow_soft')}}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="box_shadow_type" v-model="box_shadow_type" id="box_shadow_type_3" value="box_shadow_3" @change="updatePreview()">
                  <label class="form-check-label" for="box_shadow_type_3">
                    {{$t('box_shadow_hard')}}
                  </label>
                </div>
              </div>

              <div class="mt-3 fw-normal fs-6">{{$t('box_shadow_color')}}</div>
              <ColorPicker :value="box_shadow_color" @change="changeBoxShadowColor" key="button_box_shadow_color"></ColorPicker>

            </div>
            <div class="p-3">
              <div class="fw-bold fs-6">{{$t('button_text')}}</div>
              <div class="mt-2 fw-normal fs-6">{{$t('text_color')}}</div>
              <ColorPicker :value="text_color" @change="changeTextColor" key="button_text_color"></ColorPicker>
              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('font_size')}}</div>
                <div class="text-primary fw-semibold">{{text_size}} px</div>
              </div>
              <input type="range" class="form-range" min="13" max="24" step="1" v-model="text_size" id="button_font_size" @change="updatePreview()">
              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('font_weight')}}</div>
                <div class="text-primary fw-semibold">{{font_weight}}</div>
              </div>
              <input type="range" class="form-range" min="300" max="900" step="100" v-model="font_weight" id="title_font_weight" @change="updatePreview()">
              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('line_height')}}</div>
                <div class="text-primary fw-semibold">{{line_height}}</div>
              </div>
              <input type="range" class="form-range" min="1" max="2" step="0.1" v-model="line_height" id="title_line_height" @change="updatePreview()">
            </div>
          </div>
          <div class="modal-footer flex-nowrap">
            <button type="button" class="btn btn-outline-secondary w-50" data-bs-dismiss="modal">{{$t('discard')}}</button>
            <button type="button" class="btn btn-outline-primary w-50" @click="saveSettings()">{{$t('save')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import icon_svg from "@/utils/icon_svg.js"
import utils from "@/utils/utils.js"
import request from '@/api/request.js'
import predefined from "@/utils/predefined.js"
import { mapGetters } from 'vuex'
import ColorPicker from '@/views/index/components/settings/color_picker.vue'
export default {
    name: 'ButtonSetting',
    components: {
        ColorPicker
    },
    props: ['local_settings'],
    data: () => ({
      icon_svg: icon_svg,
      predefined: predefined,
      text_size: 17,
      font_weight: 500,
      line_height: 1.2,
      text_color: '',
      box_shadow_type: 'box_shadow_1',
      box_shadow_color: '',
      border_color: '',
      background_color: '',
      border_radius: 8,
      border_width: 1,
      preview_style: ''
    }),
    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        settings: 'mypage/getSettings',
        page_id: 'mypage/getPageId'
      })
    },
    created(){
      // this.initData()
    },
    mounted(){
    },
    methods: {
      initData(){
        this.border_color = this.local_settings.button_border_color
        this.background_color = this.local_settings.button_background_color
        this.text_color = this.local_settings.button_text_color
        this.border_width = this.local_settings.button_border_width
        this.box_shadow_type = this.local_settings.button_box_shadow_type
        this.box_shadow_color = this.local_settings.button_box_shadow_color
        this.text_size = this.local_settings.button_text_size
        this.line_height = this.local_settings.button_text_line_height
        this.font_weight = this.local_settings.button_text_font_weight
        this.border_radius = this.local_settings.button_border_radius
        this.updatePreview()
      },
      changeBorderColor(color){
        this.border_color = color
        this.updatePreview()
      },
      changeBackgroundColor(color){
        this.background_color = color
        this.updatePreview()
      },
      changeTextColor(color){
        this.text_color = color
        this.updatePreview()
      },
      changeBoxShadowColor(color){
        this.box_shadow_color = color
        this.updatePreview()
      },
      updatePreview(){
        this.preview_style = 'font-family: var(--mypage-paragraph-font-family); padding: 0.5rem 1rem; color:'+this.text_color+'; border:'+this.border_width+'px solid '+this.border_color+'; border-radius:'+this.border_radius+'px;' + 'font-size:'+this.text_size+'px; font-weight:'+this.font_weight+'; line-height:'+this.line_height+'; background-color:'+this.background_color+';'
        switch(this.box_shadow_type){
            case 'box_shadow_1':
              this.preview_style += 'box-shadow:none;'
              break;
            case 'box_shadow_2':
              this.preview_style += 'box-shadow:0px 5px 12px '+this.box_shadow_color+';'
              break;
            case 'box_shadow_3':
              this.preview_style += 'box-shadow:5px 5px 0px '+this.box_shadow_color+';'
              break;
            default:
              break;
        }
      },
      saveSettings(){
        var update_data = [
            { 'key': 'button_border_color', 'value': this.border_color },
            { 'key': 'button_background_color', 'value': this.background_color },
            { 'key': 'button_text_color', 'value': this.text_color },
            { 'key': 'button_border_width', 'value': this.border_width },
            { 'key': 'button_border_radius', 'value': this.border_radius },
            { 'key': 'button_box_shadow_type', 'value': this.box_shadow_type },
            { 'key': 'button_box_shadow_color', 'value': this.box_shadow_color },
            { 'key': 'button_text_size', 'value': this.text_size },
            { 'key': 'button_text_line_height', 'value': this.line_height },
            { 'key': 'button_text_font_weight', 'value': this.font_weight }
        ]
        this.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
        utils.updateCSSVariables(this.settings)
        this.update_setting()
        bootstrap.Modal.getOrCreateInstance(document.getElementById('buttonSettingModal')).hide()
      },
      update_setting(){
        var self = this
        var postData = {
          page_id: this.page_id,
          setting: JSON.stringify(this.settings)
        }
        request.page_update_setting( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'});
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'});
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'});
        })
      }
    },
    watch: {
      settings(){
        this.initData()
      },
      local_settings(){
        this.initData()
      }
    }
};
</script>

<style>
.modal-button-setting .title-color{
  width: 2.5rem; 
  height: 2.5rem; 
  border: 1px solid #dee2e6;
}
.modal-button-setting .title-color.selected,
.modal-button-setting .title-color.selected{
  border: 4px solid #FFFFFF;
  box-shadow: 0 2px 4px 4px rgb(0 0 0 / 10%);
}

.modal-button-setting .preview{
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 24px 0;
  background-color: var(--mypage-block-background-color);
  box-shadow: 0 -1px 2px rgb(0 0 0 / 7%), 0 -2px 4px rgb(0 0 0 / 7%), 0 -4px 8px rgb(0 0 0 / 7%), 0 -8px 16px rgb(0 0 0 / 7%);
}
.button-setting-modal.modal-open .modal-backdrop{
  opacity: 0;
}
</style>