<template>
  <div class="theme-store h-100" >
    <div style="padding: 24px 12px 24px 12px;">
      <div class="fs-5" style="font-weight:500;">
        {{ $t('use_empty_template_title') }}
      </div>
      <div class="mt-2" style="display: grid; grid-template-columns: repeat(3, minmax(0px, 1fr)); gap: 1.1rem 0.75rem; align-items: center;">
        <div class="theme-item w-100" @click="openPreviewModal('default')" :style="theme_item_style">
            <div class="w-100 position-relative h-100" :style="theme_item_style">
              <div class="theme-image" :style="'background: url(\'/assets/img/themes/default.png?v='+build_version+'\') center top / cover;'"></div>
              <div class="theme-text position-absolute bottom-0 bg-white w-100 text-center py-2">
                {{ $t('use_empty_template') }}
              </div>
            </div>
        </div>
      </div>
    </div>

    <div class="mt-4" style="padding: 24px 12px 120px 12px;">
      <div class="fs-5" style="font-weight:500;">
        {{ $t('use_template') }}
      </div>

      <div class="fs-6 mt-2" style="font-weight:500;">
        {{ $t('ecommerce') }}
      </div>
      <div class="mt-2" style="display: grid; grid-template-columns: repeat(3, minmax(0px, 1fr)); gap: 1.1rem 0.75rem; align-items: center;">
        <div class="theme-item w-100" @click="openPreviewModal('technology')" :style="theme_item_style">
            <div class="w-100 position-relative h-100" :style="theme_item_style">
              <div class="theme-image" :style="'background: url(\'/assets/img/themes/technology.jpg?v='+build_version+'\') center top / cover;'"></div>
              <div class="theme-text position-absolute bottom-0 bg-white w-100 text-center py-2">
                {{ $t('technology') }}
              </div>
            </div>
        </div>
        <div class="theme-item w-100" @click="openPreviewModal('fashion')" :style="theme_item_style">
            <div class="w-100 position-relative h-100" :style="theme_item_style">
              <div class="theme-image" :style="'background: url(\'/assets/img/themes/fashion.jpg?v='+build_version+'\') center top / cover;'"></div>
              <div class="theme-text position-absolute bottom-0 bg-white w-100 text-center py-2">
                {{ $t('fashion') }}
              </div>
            </div>
        </div>
        <div class="theme-item w-100" @click="openPreviewModal('health')" :style="theme_item_style">
            <div class="w-100 position-relative h-100" :style="theme_item_style">
              <div class="theme-image" :style="'background: url(\'/assets/img/themes/health.jpg?v='+build_version+'\') center top / cover;'"></div>
              <div class="theme-text position-absolute bottom-0 bg-white w-100 text-center py-2">
                {{ $t('health') }}
              </div>
            </div>
        </div>
        <div class="theme-item w-100" @click="openPreviewModal('basic')" :style="theme_item_style">
            <div class="w-100 position-relative h-100" :style="theme_item_style">
              <div class="theme-image" :style="'background: url(\'/assets/img/themes/basic.jpg?v='+build_version+'\') center top / cover;'"></div>
              <div class="theme-text position-absolute bottom-0 bg-white w-100 text-center py-2">
                {{ $t('basic') }}
              </div>
            </div>
        </div>
      </div>

      <div class="fs-6 mt-3" style="font-weight:500;">
        {{ $t('financical_service') }}
      </div>
      <div class="mt-2" style="display: grid; grid-template-columns: repeat(3, minmax(0px, 1fr)); gap: 1.1rem 0.75rem; align-items: center;">
        <div class="theme-item w-100" @click="openPreviewModal('financical_service')" :style="theme_item_style">
            <div class="w-100 position-relative h-100" :style="theme_item_style">
              <div class="theme-image" :style="'background: url(\'/assets/img/themes/financical_service.jpg?v='+build_version+'\') center top / cover;'"></div>
              <div class="theme-text position-absolute bottom-0 bg-white w-100 text-center py-2">
                {{ $t('financical_service') }}
              </div>
            </div>
        </div>
      </div>

      <div class="fs-6 mt-3" style="font-weight:500;">
        {{ $t('education') }}
      </div>
      <div class="mt-2" style="display: grid; grid-template-columns: repeat(3, minmax(0px, 1fr)); gap: 1.1rem 0.75rem; align-items: center;">
        <div class="theme-item w-100" @click="openPreviewModal('education_children')" :style="theme_item_style">
            <div class="w-100 position-relative h-100" :style="theme_item_style">
              <div class="theme-image" :style="'background: url(\'/assets/img/themes/education_children.jpg?v='+build_version+'\') center top / cover;'"></div>
              <div class="theme-text position-absolute bottom-0 bg-white w-100 text-center py-2">
                {{ $t('education_children') }}
              </div>
            </div>
        </div>
        <div class="theme-item w-100" @click="openPreviewModal('education_adult')" :style="theme_item_style">
            <div class="w-100 position-relative h-100" :style="theme_item_style">
              <div class="theme-image" :style="'background: url(\'/assets/img/themes/education_adult.jpg?v='+build_version+'\') center top / cover;'"></div>
              <div class="theme-text position-absolute bottom-0 bg-white w-100 text-center py-2">
                {{ $t('education_adult') }}
              </div>
            </div>
        </div>
      </div>
    </div>

    <div class="px-3 position-fixed" style="bottom:1rem; width:100%; max-width: var(--max-width-custom);">
      <button v-if="language == 'vn'" @click="openGuidePage()" class="btn btn-20230526 d-flex align-items-center justify-content-center ">Hướng dẫn sử dụng<i class="bi bi-question-circle-fill ms-1"></i></button>
    </div>
    
    <div class="modal preview-theme-modal" id="previewThemeModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
        <div class="modal-content"  style="background-color:#FFFFFF;">
          <div class="modal-header">
            <div class="w-100 d-flex flex-row justify-content-between" style="max-width: var(--max-width-custom); margin: 0 auto;">
              <button type="button" class="btn btn-back d-flex align-items-center" @click="backToThemeStore()" style="min-width: 2rem;">
                <span v-html="icon_svg.bi_chevron_left()"></span>
              </button>
              <div class="title d-flex align-items-center px-2">
                  <span v-html="modal_title"></span> 
              </div>
              <button type="button" id="buttonSwitchPreview" class="btn d-flex align-items-center" style="min-width: 2rem;"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                :data-bs-title="preview_tooltip_guide" 
                @click="switchPreviewThemeWithData(theme_id)"
              >
                <i v-if="this.preview_with_data == true" class="bi bi-toggle-on"></i>
                <i v-if="this.preview_with_data == false" class="bi bi-toggle-off"></i>
              </button> 
            </div>
          </div>
          <div class="modal-body p-0 h-100">
            <div v-if="show_spinner == true" class="text-center mt-3">
              <div class="spinner-border mypage-spinner-border" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            <iframe id="preview-theme-iframe" class="w-100 h-100" :src="mypage_link" @load="updateView()">
            </iframe>  
          </div>
          <div class="modal-footer p-1">
            <div class="modal-footer-inner w-100 d-flex flex-row justify-content-between" style="max-width: var(--max-width-custom); margin: 0 auto;">
              <button type="button" class="btn btn-outline-secondary w-50" @click="backToThemeStore()">{{$t('back')}}</button>
              <button type="button" class="btn btn-outline-primary w-50" @click="openOptionModal()">{{$t('apply')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div class="modal modal-confirm-change-theme modal-change-theme-option" id="changeThemeOptionModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered animate__animated animate__zoomIn">
        <div class="modal-content">
          <div class="modal-header">
            <div class="fw-bold fs-6">{{$t('apply_theme')}}</div>
            <button type="button" class="btn-close" aria-label="Close" @click="closeChangeThemeOptionModal()"></button>
          </div>
          <div class="modal-body">
            <button type="button" class="btn btn-outline-primary w-100" @click="openConfirmModal(false)"><span v-html="$t('apply_2')"></span></button>
            <button type="button" class="btn btn-outline-primary w-100 mt-3" @click="openConfirmModal(true)"><span v-html="$t('apply_theme_with_sample_data_2')"></span></button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-confirm-change-theme modal-confirm-apply-theme" id="confirmApplyThemeModal" data-bs-keyboard="false"  aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered ">
        <div class="modal-content animate__animated animate__zoomIn">
          <div class="modal-body">
            <div class="text-center fs-6">{{apply_theme_guide}}</div>
          </div>
          <div class="modal-footer flex-nowrap">
            <button type="button" class="btn btn-outline-secondary w-50" @click="closeConfirmApplyThemeModal">{{$t('cancel')}}</button>
            <button type="button" class="btn btn-danger w-50" @click="applyThemeWithData(apply_theme_with_data)">{{$t('confirm')}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-change-theme-progress" id="changeThemeProgressModal" data-bs-backdrop="static" data-bs-keyboard="false"  aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered animate__animated animate__zoomIn">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <div class="spinner-border mypage-spinner-border" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import icon_svg from "@/utils/icon_svg.js"
import { mapGetters } from 'vuex'
import predefined from "@/utils/predefined.js"
import utils from "@/utils/utils.js"
import request from '@/api/request.js'
import Cookies from 'js-cookie'
// import VLazyImage from "v-lazy-image/v2"
export default {
    name: 'ThemeStore',
    components: {
      // VLazyImage
    },
    mounted(){
    },
    data: () => ({
      icon_svg: icon_svg,
      predefined: predefined,
      mypage_link: '',
      theme_id: '',
      data_id: '',
      show_spinner: false,
      preview_with_data: false,
      modal_title: '',
      apply_theme_with_data: false,
      apply_theme_guide: '',
      preview_tooltip_guide: '',
      theme_item_style: '',
      build_version: ''
    }),
    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        language: 'builder/getLanguage',
        tab_settings: 'builder/tabSettings',
        settings: 'mypage/getSettings',
        page_id: 'mypage/getPageId',
        subdomain: 'mypage/getSubdomain', 
        current_domain: 'mypage/getCurrentDomain',
        new_user: 'builder/getNewUser',
        number_of_blocks: 'mypage/numberOfBlocks'
      })
    },
    created(){
      this.build_version = process.env.VUE_APP_VERSION
    }, 
    methods: {
      openPreviewModal(theme_id){
        // console.log(this.number_of_blocks)
        if (this.new_user == true || this.number_of_blocks == 0){
          this.preview_with_data = true
        }else{
          this.preview_with_data = false
        }
        this.preview_tooltip_guide = this.$t('preview_theme_with_sample_data')
        this.mypage_link = ''
        this.modal_title = this.$t('preview_theme')
        document.getElementById('preview-theme-iframe').classList.add('d-none')
        this.show_spinner = true
        this.theme_id = theme_id
        this.data_id = theme_id

        document.getElementById('previewThemeModal').addEventListener('show.bs.modal', () => { document.body.classList.add('preview-theme-modal') });
        document.getElementById('previewThemeModal').addEventListener('hide.bs.modal', () => { document.body.classList.remove('preview-theme-modal') });
        bootstrap.Modal.getOrCreateInstance(document.getElementById('previewThemeModal')).show()
        if (this.preview_with_data == true){
          this.load_preview_theme_with_sample_data(theme_id)
        }else{
          this.load_preview_theme(theme_id)
        }
      },
      updateView(){
        this.show_spinner = false
        document.getElementById('preview-theme-iframe').classList.remove('d-none')
      },
      updateThemeImageStyle(){
        var ele = document.querySelector(".theme-store .theme-item")
        var width = ele.offsetWidth
        var height = (width/9)*16
        this.theme_item_style = "height:" + height +"px;"
        // console.log(height)
      },
      switchPreviewThemeWithData(theme_id){
        this.mypage_link = ''
        bootstrap.Tooltip.getOrCreateInstance(document.getElementById('buttonSwitchPreview')).hide()   
        this.data_id = theme_id
        this.preview_with_data = !this.preview_with_data
        if (this.preview_with_data == true){
          this.load_preview_theme_with_sample_data(theme_id)
        }else{
          this.load_preview_theme(theme_id)
        }
      },
      load_preview_theme(theme_id){
          const timestamp = Date.now()
          var self = this
          request.page_get(self.page_id, Cookies.get('token')).then(() => {
              self.modal_title = self.$t('preview_theme') 
              self.mypage_link = 'https://'+ self.subdomain + '.' + self.current_domain + '/?_' + timestamp + '#/?preview_mode=true'+'&token='+Cookies.get('token')+'&page_id='+self.page_id+'&theme_id='+theme_id
          }).catch(function (error) {
              self.$toast.open({message: error, type: 'error', position: 'top'});
          })
      },

      load_preview_theme_with_sample_data(theme_id){
          const timestamp = Date.now()
          var self = this
          self.show_spinner = true
          request.page_get_sample(self.data_id, Cookies.get('token')).then(() => {
              self.modal_title = self.$t('preview_theme_with_sample_data_mode') 
              self.mypage_link = 'https://'+ self.subdomain + '.' + self.current_domain + '/?_' + timestamp + '#/?preview_mode=true'+'&token='+Cookies.get('token')+'&page_id='+self.page_id+'&theme_id='+theme_id+'&data_id='+self.data_id
          }).catch(function (error) {
              self.$toast.open({message: error, type: 'error', position: 'top'});
          })
      },
      openOptionModal(){
        document.getElementById('changeThemeOptionModal').addEventListener('show.bs.modal', () => { document.body.classList.add('change-theme-option-modal') });
        document.getElementById('changeThemeOptionModal').addEventListener('hide.bs.modal', () => { document.body.classList.remove('change-theme-option-modal') });
        document.getElementById('changeThemeOptionModal').addEventListener('hidden.bs.modal', () => {
          document.querySelector('#changeThemeOptionModal .modal-dialog').classList.remove('animate__zoomOut')
          document.querySelector('#changeThemeOptionModal .modal-dialog').classList.add('animate__zoomIn')
        });
        bootstrap.Modal.getOrCreateInstance(document.getElementById('changeThemeOptionModal')).show()
      },
      openConfirmModal(with_data){
        if (with_data == true){
          this.apply_theme_with_data = true
          this.apply_theme_guide = this.$t('apply_theme_with_sample_data_guide')
        }else{
          this.apply_theme_with_data = false
          this.apply_theme_guide = this.$t('apply_theme_guide')
        }
        bootstrap.Modal.getOrCreateInstance(document.getElementById('changeThemeOptionModal')).hide()
        document.getElementById('confirmApplyThemeModal').addEventListener('show.bs.modal', () => { document.body.classList.add('confirm-change-theme-modal') });
        document.getElementById('confirmApplyThemeModal').addEventListener('hide.bs.modal', () => { document.body.classList.remove('confirm-change-theme-modal') });
        bootstrap.Modal.getOrCreateInstance(document.getElementById('confirmApplyThemeModal')).show()
      },
      closeConfirmApplyThemeModal(){
        bootstrap.Modal.getOrCreateInstance(document.getElementById('confirmApplyThemeModal')).hide()
        bootstrap.Modal.getOrCreateInstance(document.getElementById('changeThemeOptionModal')).show()
      },
      backToThemeStore(){
        utils.updateCSSVariables(this.settings)
        this.mypage_link = ''
        bootstrap.Modal.getOrCreateInstance(document.getElementById('previewThemeModal')).hide()

      },
      applyTheme(){
        var self = this
        var update_data = []
        var setting_array = self.predefined.themes()[self.theme_id]
        for ( var property in setting_array ) {
            var key = property
            var value = setting_array[property]
            var item = { 'key': key, 'value': value }
            update_data.push(item)
        }
        self.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
        setTimeout(()=>{ utils.updateCSSVariables(self.settings) }, 500)
        var postData = {
          page_id: self.page_id,
          setting: JSON.stringify(self.settings)
        }
        request.page_update_setting( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'});
                self.backToBuilder()
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'});
            }
        }).catch(function (error) {
            // console.log(error)
            self.$toast.open({message: error, type: 'error', position: 'top'});
        })
      },
      applyThemeWithData(apply_theme_with_data){
        if (apply_theme_with_data == false){
          this.applyTheme()
        }else{
          bootstrap.Modal.getOrCreateInstance(document.getElementById('confirmApplyThemeModal')).hide()
          bootstrap.Modal.getOrCreateInstance(document.getElementById('changeThemeProgressModal')).show()

          var self = this
          var postData = {
            page_id: self.page_id,
            template_name: self.data_id
          }
          request.page_fill_sample_data( postData ).then(response => {
              if (response.code === 2000) {
                  self.$store.dispatch('builder/setNewUser', false)
                  self.load_page_content()
              }else{
                  self.$toast.open({message: response.error, type: 'error', position: 'top'});
              }
          }).catch(function (error) {
              self.$toast.open({message: error, type: 'error', position: 'top'});
          })
        }
      },
      load_page_content(){
          var self = this
          self.show_spinner = true
          request.page_get(self.page_id).then(response => {
              if (response.code === 2000) {
                  var page_data = response.data
                  self.language = page_data.language
                  self.$store.commit('builder/SET_LANGUAGE', self.language)
                  self.$i18n.locale = self.language
                  self.$store.commit('mypage/SET_BLOCK_IDS', page_data.block_ids)
                  var block_array = [] 
                  if ('block_arr' in page_data){
                      block_array = page_data.block_arr
                  }
                  self.$store.commit('mypage/SET_BLOCKS', block_array)
                  self.applyTheme()
              }else{
                  self.$toast.open({message: response.error, type: 'error', position: 'top'});
              }
              self.show_spinner = false
          }).catch(function (error) {
              self.$toast.open({message: error, type: 'error', position: 'top'});
          })
      },
      backToBuilder(){
        if(document.getElementById('changeThemeProgressModal')){
          bootstrap.Modal.getOrCreateInstance(document.getElementById('changeThemeProgressModal')).hide()
        }
        if(document.getElementById('confirmApplyThemeModal')){
          bootstrap.Modal.getOrCreateInstance(document.getElementById('confirmApplyThemeModal')).hide()
        }
        bootstrap.Modal.getOrCreateInstance(document.getElementById('previewThemeModal')).hide()
        this.$store.dispatch('builder/setTabSettings', 'nav-2')
        setTimeout(()=>{ bootstrap.Tab.getInstance(document.querySelector('#nav-tab-mypage button[data-bs-target="#nav-2"]')).show() }, 300)
        document.body.classList.remove('disable-scroll')
      },
      closeChangeThemeOptionModal(){
        document.querySelector('#changeThemeOptionModal .modal-dialog').classList.remove('animate__zoomIn')
        document.querySelector('#changeThemeOptionModal .modal-dialog').classList.add('animate__zoomOut')
        setTimeout(()=>{ bootstrap.Modal.getOrCreateInstance(document.getElementById('changeThemeOptionModal')).hide() }, 100)
      },
      openGuidePage(){
        var link = "https://rentracks.com.vn/mypage-rentracks-huong-dan-su-dung-chi-tiet-tu-a-z/?utm_campaign=Mypage_Homepage"
        window.open(link, '_blank');
      }
    },
    watch: {
        new_user (){
          if (this.new_user == true){
            this.preview_with_data = true
          }else{
            this.preview_with_data = false
          }
        },
        tab_settings() {
          if (this.tab_settings === 'nav-1'){
            this.updateThemeImageStyle()
          }
        }
    }
};
</script>

<style>
#previewThemeModal .modal-header{
  padding: 0.5rem 0;
  border-bottom: 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%);
  z-index: 11;
}
#previewThemeModal .modal-header .btn{
  height: 2rem;
  line-height: 1;
}
#previewThemeModal .modal-header .title{
  color: #003366;
}
#previewThemeModal .modal-header .bi{
  width: 1rem;
  color: #003366;
}
#previewThemeModal .modal-body{
  overflow-y: hidden;
}
#previewThemeModal .btn-back{
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}
#previewThemeModal .modal-footer-inner .btn{
  margin: calc(var(--bs-modal-footer-gap) * .5);
}
.modal-confirm-change-theme{
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-confirm-change-theme .modal-content{
  max-width: 280px;
  margin: 0 auto;
  border: none;
}
#confirmApplyThemeModal .modal-content{

}
#confirmApplyThemeModal .modal-footer{
  box-shadow: none;
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
}
.modal-change-theme-progress{
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-change-theme-progress .modal-content{
  width: auto;
  margin: 0 auto;
  border: none;
}
.theme-store .theme-image{
  background-size: cover;
  object-fit: cover;
  object-position: top;
  aspect-ratio: 9 / 16;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.theme-store .theme-text{
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(255 255 255 / 100%);
  font-weight: 400;
}
.theme-store .theme-item{
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%) inset;
  font-size: 12px;
  font-weight: 700;
  overflow-y: hidden;
}
#buttonSwitchPreview {
  padding: 0.375rem 0.1rem;
  margin-rignt: 0.5rem;
}
#buttonSwitchPreview .bi{
    width: 2rem!important;
    font-size: 1.5rem;
    font-weight: 400;
}
#changeThemeOptionModal .modal-content,
#confirmApplyThemeModal .modal-content,
#changeThemeProgressModal .modal-content{
  background-color: var(--bs-modal-bg)!important;
  border-radius: var(--bs-modal-border-radius)!important;
}
#changeThemeOptionModal .modal-header,
#confirmApplyThemeModal .modal-header,
#changeThemeProgressModal .modal-header{
    box-shadow: none!important;
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color)!important;
    padding: var(--bs-modal-header-padding)!important;
    border-top-left-radius: var(--bs-modal-inner-border-radius)!important;
    border-top-right-radius: var(--bs-modal-inner-border-radius)!important;
}
#changeThemeOptionModal .modal-body,
#confirmApplyThemeModal .modal-body,
#changeThemeProgressModal .modal-body{
    padding: var(--bs-modal-padding)!important;
}
#changeThemeOptionModal .modal-footer,
#confirmApplyThemeModal .modal-footer,
#changeThemeProgressModal .modal-footer{
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5)!important;
    border-bottom-right-radius: var(--bs-modal-inner-border-radius)!important;
    border-bottom-left-radius: var(--bs-modal-inner-border-radius)!important;
}

.btn-20230526{
  background-color: #003366;
  color: #FFF;
  box-shadow: 0 4px 8px -4px rgb(0 51 102 / 7%), 0 8px 16px -4px rgb(0 51 102 / 70%);
  width: 100%;
}
.btn-20230526:hover,
.btn-20230526:active{
  background-color: #03498f;
  color: #FFF;
}
</style>