export default {
    predefined_colors() {
        return ['#F44336','#E91E63','#9C27B0','#673AB7','#3F51B5','#2196F3','#03A9F4','#00BCD4','#009688','#4CAF50','#8BC34A','#CDDC39','#FFEB3B','#FFC107','#FF9800','#FF5722','#795548','#607D8B', '#333333', '#555555', '#FFFFFF']
    },
    predefined_background_colors() {
        return ['#F44336FF','#E91E63FF','#9C27B0FF','#673AB7FF','#3F51B5FF','#2196F3FF','#03A9F4FF','#00BCD4FF','#009688FF','#4CAF50FF','#8BC34AFF','#CDDC39FF','#FFEB3BFF','#FFC107FF','#FF9800FF','#FF5722FF','#795548FF','#607D8BFF', '#333333FF', '#555555FF', '#FFFFFFFF']
    },
    predefined_font_weight() {
        return [400,500,600,700,800,900]
    },
    predefined_fonts() {
        return ['Aldrich', 'Alegreya', 'Be Vietnam Pro', 'Barlow Condensed', 'Crimson Text', 'Dosis', 'Lora', 'Nunito', 'Play', 'Roboto', 'Roboto Slab']
    },
    predefined_font_size() {
        return [13,14,15,16,17,18,19,20]
    },
    predefined_line_height() {
        return [1.0,1.1,1.2,1.3,1.4,1.5,1.6,1.7,1.8,1.9,2.0]
    },
    predefined_font_size_ratio() {
        return [
            { 'key': 'Minor Second', 'value': 1.067 },
            { 'key': 'Major Second', 'value': 1.125 },
            { 'key': 'Minor Third', 'value': 1.200 },
            { 'key': 'Major Third', 'value': 1.250 },
            { 'key': 'Perfect Fourth', 'value': 1.333 },
            { 'key': 'Augmented Fourth', 'value': 1.414 },
            { 'key': 'Perfect Fifth', 'value': 1.500 },
            { 'key': 'Golden Ratio', 'value': 1.618 }
        ]
    },


    predefined_social_icons(){
        return ['/assets/img/facebook.svg','/assets/img/facebook-messenger.svg','/assets/img/twitter.svg', '/assets/img/whatsapp.svg','/assets/img/tiktok.svg','/assets/img/snapchat.svg','/assets/img/instagram.svg','/assets/img/pinterest.svg','/assets/img/linkedin.svg','/assets/img/youtube.svg', '/assets/img/behance-bmc.svg', '/assets/img/twitch-bmc.svg']
    },
    
    block_text_obj(){
        return {
            content: ''
        }
    },

    block_divider_obj(){
        return {
            spacing: 4,
            divider_content: true,
            divider_content_type: 'line',    // line, image
            line_style: 'solid',    // solid, dotted, dashed 
            line_color: '#444444',
            line_width: 1,
            image_url: ''
        }
    },

    block_image_obj(){
        return {
            image_url: '',
            image_direction: 'landscape',   // landscape, portrait
            image_ratio: 'ratio_1',     // ratio_1, ratio_2, ratio_3, ratio_4, ratio_5, ratio_6
            link_type: 'web',  // web, tel, email
            link_web: '',
            link_tel:'',
            link_email:'',
            rentracks_link: '', // Rentracks affiliate link
            use_rentracks_link: true,
            open_link_in_new_tab: true
        }
    },

    block_link_obj(){
        return {
            link_design_style:'border', // border, color, shadow
            link_design_content: 'text_icon',   // text_icon, text_image, text
            link_design_content_layout: 'text_right',   //  text_right, text_left, text_bottom
            link_design_size: 'link_normal',   // link_small, link_normal, link_large
            link_image: 'https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/7b9992100f318e248ad0bb9199c55a6c',
            link_icon: '<i class="bi bi-globe2"></i>',
            link_title: '',
            link_description:'',
            link_type: 'web',  // web, tel, email
            link_web: '',
            link_tel:'',
            link_email:'',
            rentracks_link: '', // Rentracks affiliate link
            use_rentracks_link: true,
            open_link_in_new_tab: true
        }
    },

    block_bio_obj(){
        return {
            layout_style: 'style_1',   // style_1, style_2, style_3, style_4, style_5
            content: '',
            social_links:[
                { icon: '/assets/img/facebook.svg' , link: '', 'open_link_in_new_tab': true},
                { icon: '/assets/img/twitter.svg' , link: '', 'open_link_in_new_tab': true},
                { icon: '/assets/img/tiktok.svg' , link: '', 'open_link_in_new_tab': true},
                { icon: '/assets/img/instagram.svg' , link: '', 'open_link_in_new_tab': true},
                { icon: '/assets/img/pinterest.svg' , link: '', 'open_link_in_new_tab': true},
                { icon: '/assets/img/youtube.svg' , link: '', 'open_link_in_new_tab': true}
            ],
            bio_image_url: 'https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/7b9992100f318e248ad0bb9199c55a6c',
            background_image_flag: false,
            background_image_ratio: 'ratio_4',  // ratio_1, ratio_2, ratio_3, ratio_4, ratio_5, ratio_6
            background_images:[
                {image_url: ''} 
            ]
        }
    },

    block_product_obj(){
        return {
            layout_style: 'style_1',   // style_1, style_2, style_3
            number_of_column: 3,    // 1, 2, 3
            category: [
                {
                    category_id: '',
                    category_name: "Category",
                    hide: false
                }
            ],
            category_display_style: 'style_1',   // style_1, style_2
            product: [
                // {   
                //     product_id: '',
                //     product_name: '',
                //     product_link: '',
                //     image_url: 'https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/7b9992100f318e248ad0bb9199c55a6c',
                //     rentracks_link: '',
                //     use_rentracks_link: true,
                //     open_link_in_new_tab: true,
                //     category_id: 'default',
                //     tag: '',    // hot, sale_off, favourite
                //     hide: false,
                // }
            ],
            button_more_text: 'See more'
        }
    },
    product_obj(){
        return  {   
            product_id: '',
            product_name: '',
            product_link: '',
            image_url: 'https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/7b9992100f318e248ad0bb9199c55a6c',
            rentracks_link: '',
            use_rentracks_link: true,
            open_link_in_new_tab: true,
            category_id: 'default',
            hot: false,
            sale_off: false,
            favourite: false,
            hide: false,
        }
    },

    block_embedded_obj(){
        return {
            content: '',
        }
    },

    block_spotlight_obj(){
        return {
            items: [
                // {   
                //     id: '',
                //     title: '',
                //     image_url: '',
                //     link_type: 'web',  // web, tel, email
                //     link_web: '',
                //     link_tel:'',
                //     link_email:'',
                //     rentracks_link: '',
                //     use_rentracks_link: true,
                //     open_link_in_new_tab: true,
                //     hide: false,
                // }
            ]
        }
    },
    spotlight_obj(){
        return  {
            id: '',
            title: '',
            image_url: '',
            link_type: 'web',  // web, tel, email
            link_web: '',
            link_tel:'',
            link_email:'',
            rentracks_link: '',
            use_rentracks_link: true,
            open_link_in_new_tab: true,
            hide: false,
        }
    },

    block_multi_links_obj(){
        return {
            layout_style: 'text_right',    //  text_right, text_left, text_bottom, zigzag
            item_content_layout: 'text_icon',   //  text_image, text_icon, text, image, icon
            item_size: 'link_normal',   // link_small, link_normal, link_large
            number_of_item_per_row: 1,   
            items: [
                // {   
                //     link_design_style:'border', // border, color, shadow
                //     link_image: 'https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/7b9992100f318e248ad0bb9199c55a6c',
                //     link_icon: '<i class="bi bi-globe2"></i>',
                //     link_title: '',
                //     link_description:'',
                //     link_type: 'web',  // web, tel, email
                //     link_web: '',
                //     link_tel:'',
                //     link_email:'',
                //     rentracks_link: '', // Rentracks affiliate link
                //     use_rentracks_link: true,
                //     open_link_in_new_tab: true,
                //     hide: false
                // }
            ],
            button_more_text: 'See more'
        }
    },
    multi_links_item_obj(){
        return  {
            id: '',
            link_design_style:'border', // border, color, shadow
            link_image: 'https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/7b9992100f318e248ad0bb9199c55a6c',
            link_icon: '<i class="bi bi-globe2"></i>',
            link_title: '',
            link_description:'',
            link_type: 'web',  // web, tel, email
            link_web: '',
            link_tel:'',
            link_email:'',
            rentracks_link: '', // Rentracks affiliate link
            use_rentracks_link: true,
            open_link_in_new_tab: true,
            hide: false
        }
    },

    block_image_slider_obj(){
        return {
            image_direction: 'landscape',   // landscape, portrait
            image_ratio: 'ratio_4',     // ratio_1, ratio_2, ratio_3, ratio_4, ratio_5, ratio_6
            auto_play: true,
            show_nav_button: true,
            show_indicator: true, 
            items: [
                // {   
                //     id: '',
                //     title: '',
                //     image_url: '',
                //     link_type: 'web',  // web, tel, email
                //     link_web: '',
                //     link_tel:'',
                //     link_email:'',
                //     rentracks_link: '',
                //     use_rentracks_link: true,
                //     open_link_in_new_tab: true,
                //     hide: false,
                // }
            ]
        }
    },
    image_slider_obj(){
        return  {
            id: '',
            title: '',
            image_url: '',
            link_type: 'web',  // web, tel, email
            link_web: '',
            link_tel:'',
            link_email:'',
            rentracks_link: '',
            use_rentracks_link: true,
            open_link_in_new_tab: true,
            hide: false,
        }
    },

    block_card_obj(){
        return {
            layout_style: 'grid',   // grid, slide
            grid_content_style: 'style_1',    //  style_1,  style_2,  style_3,  style_4,  style_5,  style_6,  style_7,  style_8
            slide_content_style: 'style_1',    // style_1,  style_2,  style_3,  style_4,  style_5,  style_6
            slide_style: 'style_1',     // style_1, style_2
            number_of_item_per_row: 2,   
            image_direction: 'landscape',   // landscape, portrait
            image_ratio: 'ratio_4',     // ratio_1, ratio_2, ratio_3, ratio_4, ratio_5, ratio_6
            auto_play: false,
            show_nav_button: false,
            show_indicator: true,
            items: [
                // {   
                //     id: '',
                //     title: '',
                //     title_2: '',
                //     label: '',
                //     description: '',
                //     image_url: '',
                //     link_type: 'web',  // web, tel, email
                //     link_web: '',
                //     link_tel:'',
                //     link_email:'',
                //     rentracks_link: '',
                //     use_rentracks_link: true,
                //     open_link_in_new_tab: true,
                //     hide: false,
                // }
            ],
            button_more_text: 'See more'
        }
    },
    card_obj(){
        return  {
            id: '',
            title: '',
            title_2: '',
            label: '',
            description: '',
            image_url: '',
            link_type: 'web',  // web, tel, email
            link_web: '',
            link_tel:'',
            link_email:'',
            rentracks_link: '',
            use_rentracks_link: true,
            open_link_in_new_tab: true,
            hide: false,
        }
    },

    block_image_gallery_obj(){
        return {
            layout_style: 'grid',   // grid, slide, grid_auto
            slide_style: 'style_1',     // style_1, style_2
            number_of_item_per_row: 2,   
            image_direction: 'landscape',   // landscape, portrait
            image_ratio: 'ratio_4',     // ratio_1, ratio_2, ratio_3, ratio_4, ratio_5, ratio_6
            items: [
                // {   
                //     id: '',
                //     title: '',
                //     description: '',
                //     image_url: '',
                //     hide: false,
                // }
            ],
            button_more_text: 'See more'
        }
    },
    image_gallery_obj(){
        return  {
            id: '',
            title: '',
            description: '',
            image_url: '',
            hide: false,
        }
    },

    block_voucher_obj(){
        return {
            auto_voucher: true,
            hide_expired_voucher: true,
            items: [
                // {   
                // }
            ],
            button_more_text: 'See more'
        }
    },
    voucher_obj(){
        return  {
            voucher_id: '',
            voucher_name: '',
            voucher_description: '',
            voucher_code: '',
            voucher_value: 0,
            voucher_type: '%',
            datetime_start: '',
            datetime_end: '',

            product_link: '',
            image_url: 'https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/7b9992100f318e248ad0bb9199c55a6c',
            rentracks_link: '',
            use_rentracks_link: true,
            open_link_in_new_tab: true,
            hide: false,
        }
    },

    voucher_type() {
        return  [ '%', 'VND', 'USD', 'JPY', 'EUR', 'THB', 'IDR', 'TWD', 'CNY', 'HKD', 'PHP', 'MYR', 'MNT', 'INR', 'BDT']
    },

    setting_obj(){
        return {
            // Background   
            "mypage_background_type":"color",       // color, image, gradient
            "mypage_background_color":"#f5f5f5",
            "mypage_background_gradient_color_1":"#03A9F4",
            "mypage_background_gradient_color_2":"#009688",
            "mypage_background_gradient_direction":"to top right",
            "mypage_background_image":"https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/e0ae7e12127d391a6bc05eb0f4f8bbea",
            "mypage_main_color":"#FFFFFF",
            "mypage_secondary_color":"#FFFFFF",
            // Heading
            "heading_color":"#999999",
            "heading_font_size":13,
            "heading_font_family":"Nunito",
            "heading_font_weight":500,
            "heading_line_height":1.2,
            "heading_ratio":1.2,
            // Paragraph
            "paragraph_color":"#373F42FF",
            "paragraph_font_size":13,
            "paragraph_font_family":"Nunito",
            "paragraph_font_weight":400,
            "paragraph_line_height":1.2,
            "paragraph_ratio":1.2,
            // Block
            "block_background_color":"#FFFFFF",
            "block_border_radius":8,
            "block_border_width":0,
            "block_border_style":"solid",
            "block_border_color":"#607D8B",
            "block_padding":12,
            "block_margin_x":"12",
            "block_margin_bottom":12,
            "block_shadow":1,
            "block_shadow_color":"#00000033",
            // Button
            "button_border":"",
            "button_border_color":"#F8F8F8FF",
            "button_background_color":"#FFFFFF",
            "button_text_color":"#333333",
            "button_border_width":"1",
            "button_border_radius":4,
            "button_box_shadow_type":"box_shadow_2",    // box_shadow_1, box_shadow_2, box_shadow_3
            "button_box_shadow_color":"#C7C6C6FF",
            "button_text_size":"20",
            "button_text_line_height":1.2,
            "button_text_font_weight":"500"
        }
    },

    themes() {
        return {
            'default': { 
                "mypage_background_type":"color",
                "mypage_background_color":"#f5f5f5",
                "mypage_background_gradient_color_1":"#03A9F4",
                "mypage_background_gradient_color_2":"#009688",
                "mypage_background_gradient_direction":"to top right",
                "mypage_background_image":"https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/e0ae7e12127d391a6bc05eb0f4f8bbea",
                "mypage_main_color":"#333333",
                "mypage_secondary_color":"#555555",
                "heading_color":"#999999",
                "heading_font_size":13,
                "heading_font_family":"Nunito",
                "heading_font_weight":500,
                "heading_line_height":1.2,
                "heading_ratio":1.2,
                "paragraph_color":"#373F42FF",
                "paragraph_font_size":13,
                "paragraph_font_family":"Nunito",
                "paragraph_font_weight":400,
                "paragraph_line_height":1.2,
                "paragraph_ratio":1.2,
                "block_background_color":"#FFFFFF",
                "block_border_radius":8,
                "block_border_width":0,
                "block_border_style":"solid",
                "block_border_color":"#607D8B",
                "block_padding":12,
                "block_margin_x":"12",
                "block_margin_bottom":12,
                "block_shadow":1,
                "block_shadow_color":"#00000033",
                "button_border":"",
                "button_border_color":"#F8F8F8FF",
                "button_background_color":"#FFFFFF",
                "button_text_color":"#0578D4",
                "button_border_width":"0",
                "button_border_radius":4,
                "button_box_shadow_type":"box_shadow_2",
                "button_box_shadow_color":"#C7C6C6FF",
                "button_text_size":"20",
                "button_text_line_height":1.2,
                "button_text_font_weight":"500"
            },
            'basic': {    
                "mypage_background_type":"color",
                "mypage_background_color":"#090909FF",
                "mypage_background_gradient_color_1":"#03A9F4",
                "mypage_background_gradient_color_2":"#009688",
                "mypage_background_gradient_direction":"to top right",
                "mypage_background_image":"https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/e0ae7e12127d391a6bc05eb0f4f8bbea",
                "mypage_main_color":"#FFFFFFFF",
                "mypage_secondary_color":"#FFFFFFFF",
                "heading_color":"#000000FF",
                "heading_font_size":"17",
                "heading_font_family":"Barlow Condensed",
                "heading_font_weight":"500",
                "heading_line_height":"1.1",
                "heading_ratio":1.2,
                "paragraph_color":"#030303FF",
                "paragraph_font_size":"16",
                "paragraph_font_family":"Dosis",
                "paragraph_font_weight":"500",
                "paragraph_line_height":"1.2",
                "paragraph_ratio":1.2,
                "block_background_color":"#fefefe",
                "block_border_radius":"0",
                "block_border_width":0,
                "block_border_style":"solid",
                "block_border_color":"#FFFFFFFF",
                "block_padding":"12",
                "block_margin_x":0,
                "block_margin_bottom":12,
                "block_shadow":0,
                "block_shadow_color":"#00000033",
                "button_border":"",
                "button_border_color":"#363636FF",
                "button_background_color":"#FFFFFF",
                "button_text_color":"#040404FF",
                "button_border_width":"1",
                "button_border_radius":"4",
                "button_box_shadow_type":"box_shadow_2",
                "button_box_shadow_color":"#C7C6C6FF",
                "button_text_size":"20",
                "button_text_line_height":1.2,
                "button_text_font_weight":"500"
            },
            'technology': {   
                "mypage_background_type":"gradient",
                "mypage_background_color":"#090909FF",
                "mypage_background_gradient_color_1":"#186086FF",
                "mypage_background_gradient_color_2":"#012070FF",
                "mypage_background_gradient_direction":"to bottom",
                "mypage_background_image":"https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/e0ae7e12127d391a6bc05eb0f4f8bbea",
                "mypage_main_color":"#FFFFFFFF",
                "mypage_secondary_color":"#FFFFFFFF",
                "heading_color":"#000000FF",
                "heading_font_size":"15",
                "heading_font_family":"Aldrich",
                "heading_font_weight":"500",
                "heading_line_height":"1.1",
                "heading_ratio":1.2,
                "paragraph_color":"#030303FF",
                "paragraph_font_size":"13",
                "paragraph_font_family":"Aldrich",
                "paragraph_font_weight":"400",
                "paragraph_line_height":"1.3",
                "paragraph_ratio":1.25,
                "block_background_color":"#FFFFFFFF",
                "block_border_radius":"0",
                "block_border_width":0,
                "block_border_style":"solid",
                "block_border_color":"#FFFFFFFF",
                "block_padding":"12",
                "block_margin_x":"12",
                "block_margin_bottom":"16",
                "block_shadow":0,
                "block_shadow_color":"#00000033",
                "button_border":"",
                "button_border_color":"#363636FF",
                "button_background_color":"#FFFFFF",
                "button_text_color":"#040404FF",
                "button_border_width":"1",
                "button_border_radius":"4",
                "button_box_shadow_type":"box_shadow_2",
                "button_box_shadow_color":"#C7C6C6FF",
                "button_text_size":"20",
                "button_text_line_height":1.2,
                "button_text_font_weight":"500"
            },
            'education_adult': {   
                "mypage_background_type":"color",
                "mypage_background_color":"#f5f5f5",
                "mypage_background_gradient_color_1":"#03A9F4",
                "mypage_background_gradient_color_2":"#009688",
                "mypage_background_gradient_direction":"to top right",
                "mypage_background_image":"https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/e0ae7e12127d391a6bc05eb0f4f8bbea",
                "mypage_main_color":"#333333",
                "mypage_secondary_color":"#555555",
                "heading_color":"#333333",
                "heading_font_size":"17",
                "heading_font_family":"Roboto",
                "heading_font_weight":"600",
                "heading_line_height":1.2,
                "heading_ratio":1.125,
                "paragraph_color":"#373F42FF",
                "paragraph_font_size":13,
                "paragraph_font_family":"Roboto",
                "paragraph_font_weight":400,
                "paragraph_line_height":1.2,
                "paragraph_ratio":1.067,
                "block_background_color":"#FFFFFF",
                "block_border_radius":8,
                "block_border_width":0,
                "block_border_style":"solid",
                "block_border_color":"#607D8B",
                "block_padding":12,
                "block_margin_x":"12",
                "block_margin_bottom":12,
                "block_shadow":1,
                "block_shadow_color":"#00000033",
                "button_border":"",
                "button_border_color":"#333333",
                "button_background_color":"#FFFFFF",
                "button_text_color":"#333333",
                "button_border_width":"2",
                "button_border_radius":4,
                "button_box_shadow_type":"box_shadow_2",
                "button_box_shadow_color":"#C7C6C6FF",
                "button_text_size":"20",
                "button_text_line_height":1.2,
                "button_text_font_weight":"500"
            },
            'education_children': {   
                "mypage_background_type":"gradient",
                "mypage_background_color":"#090909FF",
                "mypage_background_gradient_color_1":"#EAD445FF",
                "mypage_background_gradient_color_2":"#D77A29FF",
                "mypage_background_gradient_direction":"to bottom",
                "mypage_background_image":"https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/e0ae7e12127d391a6bc05eb0f4f8bbea",
                "mypage_main_color":"#FFFFFFFF",
                "mypage_secondary_color":"#FFFFFFFF",
                "heading_color":"#000000FF",
                "heading_font_size":"15",
                "heading_font_family":"Roboto Slab",
                "heading_font_weight":"500",
                "heading_line_height":"1.1",
                "heading_ratio":1.2,
                "paragraph_color":"#030303FF",
                "paragraph_font_size":"13",
                "paragraph_font_family":"Roboto Slab",
                "paragraph_font_weight":"400",
                "paragraph_line_height":"1.3",
                "paragraph_ratio":1.125,
                "block_background_color":"#FFFFFFFF",
                "block_border_radius":"12",
                "block_border_width":"1",
                "block_border_style":"solid",
                "block_border_color":"#FFFFFFFF",
                "block_padding":"12",
                "block_margin_x":"12",
                "block_margin_bottom":"16",
                "block_shadow":0,
                "block_shadow_color":"#00000033",
                "button_border":"",
                "button_border_color":"#363636FF",
                "button_background_color":"#FFFFFF",
                "button_text_color":"#D7D7D7FF",
                "button_border_width":"1",
                "button_border_radius":"4",
                "button_box_shadow_type":"box_shadow_2",
                "button_box_shadow_color":"#C7C6C6FF",
                "button_text_size":"20",
                "button_text_line_height":1.2,
                "button_text_font_weight":"500"
            },
            'health': {   
                "mypage_background_type":"color",
                "mypage_background_color":"#F6DFDDFF",
                "mypage_background_gradient_color_1":"#03A9F4",
                "mypage_background_gradient_color_2":"#009688",
                "mypage_background_gradient_direction":"to top right",
                "mypage_background_image":"https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/e0ae7e12127d391a6bc05eb0f4f8bbea",
                "mypage_main_color":"#E91E63",
                "mypage_secondary_color":"#03A9F4",
                "heading_color":"#333333",
                "heading_font_size":"17",
                "heading_font_family":"Dosis",
                "heading_font_weight":"600",
                "heading_line_height":1.2,
                "heading_ratio":1.25,
                "paragraph_color":"#333333",
                "paragraph_font_size":"14",
                "paragraph_font_family":"Dosis",
                "paragraph_font_weight":"600",
                "paragraph_line_height":1.2,
                "paragraph_ratio":1.618,
                "block_background_color":"#FFFFFF",
                "block_border_radius":8,
                "block_border_width":"4",
                "block_border_style":"solid",
                "block_border_color":"#FFFFFF",
                "block_padding":"8",
                "block_margin_x":"12",
                "block_margin_bottom":12,
                "block_shadow":1,
                "block_shadow_color":"#00000033",
                "button_border":"",
                "button_border_color":"#F8F8F8FF",
                "button_background_color":"#F6DFDDFF",
                "button_text_color":"#A3A3A3",
                "button_border_width":"5",
                "button_border_radius":"8",
                "button_box_shadow_type":"box_shadow_2",
                "button_box_shadow_color":"#C7C6C6FF",
                "button_text_size":"21",
                "button_text_line_height":"1.4",
                "button_text_font_weight":"700"
            },
            'financical_service': {   
                "mypage_background_type":"gradient",
                "mypage_background_color":"#090909FF",
                "mypage_background_gradient_color_1":"#398C2CFF",
                "mypage_background_gradient_color_2":"#1B4F31FF",
                "mypage_background_gradient_direction":"to bottom",
                "mypage_background_image":"https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/e0ae7e12127d391a6bc05eb0f4f8bbea",
                "mypage_main_color":"#FFFFFFFF",
                "mypage_secondary_color":"#FFFFFFFF",
                "heading_color":"#000000FF",
                "heading_font_size":"14",
                "heading_font_family":"Roboto",
                "heading_font_weight":"600",
                "heading_line_height":"1.1",
                "heading_ratio":1.2,
                "paragraph_color":"#030303FF",
                "paragraph_font_size":"13",
                "paragraph_font_family":"Roboto",
                "paragraph_font_weight":"400",
                "paragraph_line_height":"1.3",
                "paragraph_ratio":1.25,
                "block_background_color":"#FFFFFFFF",
                "block_border_radius":"0",
                "block_border_width":0,
                "block_border_style":"solid",
                "block_border_color":"#FFFFFFFF",
                "block_padding":"12",
                "block_margin_x":"12",
                "block_margin_bottom":"16",
                "block_shadow":0,
                "block_shadow_color":"#00000033",
                "button_border":"",
                "button_border_color":"#194D33FF",
                "button_background_color":"#FFFFFF",
                "button_text_color":"#333333",
                "button_border_width":"6",
                "button_border_radius":"4",
                "button_box_shadow_type":"box_shadow_2",
                "button_box_shadow_color":"#C7C6C6FF",
                "button_text_size":"20",
                "button_text_line_height":1.2,
                "button_text_font_weight":"500"
            },
            'fashion': {   
                "mypage_background_type":"gradient",
                "mypage_background_color":"#090909FF",
                "mypage_background_gradient_color_1":"#F3682DFF",
                "mypage_background_gradient_color_2":"#B62C20FF",
                "mypage_background_gradient_direction":"to bottom",
                "mypage_background_image":"https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/e0ae7e12127d391a6bc05eb0f4f8bbea",
                "mypage_main_color":"#FFFFFFFF",
                "mypage_secondary_color":"#FFFFFFFF",
                "heading_color":"#000000FF",
                "heading_font_size":"18",
                "heading_font_family":"Barlow Condensed",
                "heading_font_weight":"600",
                "heading_line_height":"1.1",
                "heading_ratio":1.2,
                "paragraph_color":"#030303FF",
                "paragraph_font_size":"20",
                "paragraph_font_family":"Barlow Condensed",
                "paragraph_font_weight":"500",
                "paragraph_line_height":"1.2",
                "paragraph_ratio":1.2,
                "block_background_color":"#FFFFFFFF",
                "block_border_radius":"6",
                "block_border_width":0,
                "block_border_style":"solid",
                "block_border_color":"#FFFFFFFF",
                "block_padding":"12",
                "block_margin_x":"12",
                "block_margin_bottom":"16",
                "block_shadow":0,
                "block_shadow_color":"#00000033",
                "button_border":"",
                "button_border_color":"#363636FF",
                "button_background_color":"#FFFFFF",
                "button_text_color":"#040404FF",
                "button_border_width":"1",
                "button_border_radius":"4",
                "button_box_shadow_type":"box_shadow_2",
                "button_box_shadow_color":"#C7C6C6FF",
                "button_text_size":"20",
                "button_text_line_height":1.2,
                "button_text_font_weight":"500"
            },

        }
    }
}