<template>
  <div class="w-100 h-100 d-flex flex-column" style="background-color:#FFFFFF;">
    <div class="flex-grow-1 d-flex flex-column" style="padding: 24px 12px 120px 12px;">
      <div class="">
        <div class="fw-bold fs-6">{{$t('page_domain')}}</div>
        <div class="d-flex flex-row">
          <div class="flex-grow-1 pe-2">
            <input type="text" class="form-control" v-model="local_page_subdomain" placeholder="Subdomain" v-on:keyup="checkValidSubdomain()" @change="checkValidSubdomain()">
          </div>
          <div class="w-50 d-flex align-items-end">
            <select class="form-select" aria-label=".form-select" v-model="local_page_domain">
              <option v-for="(item, index) in domain_list" v-bind:key="index" :value="item">{{item}}</option>
            </select>
          </div>
        </div>
        <div>
          <small v-if="subdomain_valid == true" class="text-success"><i class="bi bi-check-circle-fill me-1"></i>{{$t('subdomain_valid')}}</small>
          <small v-if="subdomain_valid == false" class="text-danger"><i class="bi bi-x-circle-fill me-1"></i>{{$t('subdomain_invalid')}}</small>
        </div>
      </div>
      <div class="mt-3">
        <div class="fw-bold fs-6">{{$t('page_title')}}</div>
        <input type="text" class="form-control" :placeholder="$t('page_title')" v-model="local_page_name">
      </div>
      <div class="mt-3">
        <div class="fw-bold fs-6">{{$t('page_description')}}</div>
        <textarea class="form-control" rows="3" v-model="local_page_description"></textarea>
      </div>
      <div class="mt-3">
        <div class="fw-bold fs-6">{{$t('favicon')}}</div>
        <div class="mt-2 d-flex flex-row">
          <div class="me-3">
            <div class="img-wrapper" :style="'background-image:url('+local_page_favicon+');'">
            </div>
          </div>
          <div class="d-flex flex-column justify-content-between">
            <div class="text-black-50" style="line-height: 1;">{{$t('image_guide')}}</div>
            <div class="d-flex flex-row">
              <button type="button"  class="btn btn-outline-secondary" @click="deleteImage()">
                <i class="bi bi-trash3"></i>
              </button>
              <button :disabled="upload_icon_progress == true" type="button" class="btn btn-primary w-100 ms-2" @click="performClickUpload()">
                <div class="align-middle">
                  <div v-if="upload_icon_progress == true" class="spinner-border text-light" role="status" style="width: 1rem; height: 1rem; border-width: 0.15rem;">
                    <span class="visually-hidden">...</span>
                  </div>
                  {{$t('upload_image')}}
                </div>
              </button>
              <div class="d-none"><input type="file" id="icon_image_file" @change="uploadImage($event)"/></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mypage-setting-buttons d-flex flex-row justify-content-end flex-nowrap fixed-bottom bg-white" style="max-width: var(--max-width-custom); margin: 0 auto;">
      <button :disabled="disable_button == true && subdomain_valid == false" type="button" class="btn btn-outline-primary w-100" @click="savePageSetting()">{{$t('save')}}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from "vue"
import request from '@/api/request.js'

export default {
    name: 'MypageSetting',
    components: {
    },
    props: [],
    data: () => ({
      local_page_domain: '',
      local_page_subdomain: '',
      local_page_name: '',
      local_page_description: '',
      local_page_favicon: 'https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/413811b31ae6c1898683f9ee706c9d90',
      upload_icon_progress: false,
      disable_button: false,
      subdomain_valid: null,
    }),
    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        settings: 'mypage/getSettings',
        blocks: 'mypage/getBlocks',
        block_ids: 'mypage/getBlockIds',
        page_id: 'mypage/getPageId',
        subdomain: 'mypage/getSubdomain',
        current_domain: 'mypage/getCurrentDomain',
        domain_list: 'mypage/getDomainList',
        page_name: 'mypage/getPageName',
        page_description: 'mypage/getPageDescription',
        page_favicon: 'mypage/getPageFavicon',
      }),
     
    },
    mounted(){
      this.initData()
    },
    methods: {
      initData(){
        this.local_page_domain = this.current_domain
        this.local_page_subdomain = this.subdomain
        this.local_page_name = this.page_name
        this.local_page_description = this.page_description
        if (this.page_favicon != '') {
          this.local_page_favicon = this.page_favicon
        }
      },
      performClickUpload(){
        document.getElementById('icon_image_file').click()
      },
      uploadImage(event){
        var self = this
        self['upload_'+this.image_key + '_progress'] = true
        let formData = new FormData();
        formData.append('image_file', event.target.files[0]);
        formData.append('quality', 75);
        formData.append('max_size', 120);
        request.upload_image( formData ).then(response => {
            if (response.code == 2000){
              var image_url = response.data
              Vue.set(self, 'local_page_favicon', image_url)
            }else{
              self.$toast.open({message: response.error, type: 'error', position: 'top'});
            }
            document.getElementById('icon_image_file').value = ''
            self.upload_icon_progress = false
        }).catch(function (error) {
            self.upload_image_progress = false
            self.$toast.open({message: error, type: 'error', position: 'top'});
        })
      },
      deleteImage(){
        this.local_page_favicon = 'https://asp-photo-upload.s3.ap-southeast-1.amazonaws.com/413811b31ae6c1898683f9ee706c9d90'
      },
      savePageSetting(){
        var self = this
        self.disable_button = true
        var postData = {
          page_id: self.page_id,
          subdomain: self.local_page_subdomain,
          domain: self.local_page_domain,
          page_name: self.local_page_name,
          page_description: self.local_page_description,
          page_icon: self.local_page_favicon
        }
        request.page_setting( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'});
                self.$store.commit('mypage/SET_SUBDOMAIN', self.local_page_subdomain)
                self.$store.commit('mypage/SET_CURRENT_DOMAIN', self.local_page_domain)
                self.$store.commit('mypage/SET_PAGE_NAME', self.local_page_name)
                self.$store.commit('mypage/SET_PAGE_DESCRIPTION', self.local_page_description)
                self.$store.commit('mypage/SET_PAGE_FAVICON', self.local_page_favicon)
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'});
            }
            self.disable_button = false
        }).catch(function (error) {
            self.disable_button = false
            self.$toast.open({message: error, type: 'error', position: 'top'});
        })
      },
      checkValidSubdomain(){
        var self = this
        var data = {
          page_id: this.page_id,
          subdomain: this.local_page_subdomain
        }
        request.page_check_subdomain( data ).then(response => {
            if (response.code === 2000) {
                if (response.status === true){
                  self.subdomain_valid = true
                  self.disable_button = false
                }else{
                  self.subdomain_valid = false
                  self.disable_button = true
                }
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'});
        })
      }
    },
    watch: {
      page_id() {
        this.initData()
      }
    }
};
</script>

<style>
.mypage-setting-buttons{
  box-shadow: rgb(0 0 0 / 7%) 0px -1px 2px, rgb(0 0 0 / 7%) 0px -2px 4px, rgb(0 0 0 / 7%) 0px -4px 8px, rgb(0 0 0 / 7%) 0px -8px 16px, rgb(0 0 0 / 7%) 0px -16px 32px;
  z-index: 1;
  padding: 16px 12px;
}
</style>