<template>
  <div>
    <div class="modal modal-setting modal-background-setting" id="backgroundSettingModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="backgroundSettingModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
        <div class="modal-content builder-modal-content modal-content-block-setting">
          <div class="modal-header" style="background-color:#FFFFFF;">
            <div class="w-100 d-flex flex-row justify-content-between">
              <button type="button" class="btn btn-back d-flex align-items-center" @click="closeModalAndUpdateCSS()">
                <span v-html="icon_svg.bi_chevron_left()"></span>
              </button>
              <div class="title fs-5 d-flex align-items-center" id="backgroundSettingModalLabel">{{$t('background_setting')}}</div>
              <div style="min-width: 2rem;"></div>
            </div>
          </div>
          <div class="modal-body p-0 pb-5" style="background-color:#FFFFFF; overflow-y: scroll;">
            <div class="preview sticky-top">
              <div class="preview-block">
                <div style="width: 100%; height: 80px; background-color: #DDDDDD;">
                </div>
              </div>
              <div class="preview-block">
                <div style="width: 100%; height: 80px; background-color: #DDDDDD;">
                </div>
              </div>
            </div>
            <div class="p-3">
              <div class="fw-bold fs-6">{{$t('background')}}</div>
              <div class="mt-3 fw-normal fs-6">{{$t('background_type')}}</div>
              <div class="d-flex flex-row">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="background_type" value="image" v-model="background_type" id="background_type_1" @change="updatePreview()">
                  <label class="form-check-label pe-2" for="background_type_1">
                    {{$t('image')}}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="background_type" value="color" v-model="background_type" id="background_type_2" @change="updatePreview()">
                  <label class="form-check-label pe-2" for="background_type_2">
                    {{$t('color')}}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="background_type" value="gradient" v-model="background_type" id="background_type_3" @change="updatePreview()">
                  <label class="form-check-label pe-2" for="background_type_3">
                    {{$t('gradient')}}
                  </label>
                </div>
              </div>

              <div v-if="background_type === 'color'" class="mt-2 d-flex flex-wrap">
                <ColorPicker :value="backround_color" @change="changeBackgroundColor" key="backround_color"></ColorPicker>
              </div>
              <div v-if="background_type === 'image'" class="mt-2 d-flex flex-row">
                <div class="me-3">
                  <div class="img-wrapper" :style="'background-image:url('+this.background_image+');'">
                  </div>
                </div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="text-black-50" style="line-height: 1;">{{$t('background_guide')}}</div>
                  <button :disabled="upload_image_progress == true" type="button" class="btn btn-primary w-100" @click="performClickUpload()">
                    <div class="align-middle">
                      <div v-if="upload_image_progress == true" class="spinner-border text-light" role="status" style="width: 1rem; height: 1rem; border-width: 0.15rem;">
                        <span class="visually-hidden">...</span>
                      </div>
                      {{$t('upload_image')}}
                    </div>
                  </button>
                </div>
              </div>
              <div v-if="background_type === 'gradient'" class="mt-2">
                <div class="mt-3 fw-normal fs-6">{{$t('start_color')}}</div>
                <ColorPicker :value="background_gradient_color_1" @change="changeGradientColor1" key="gradient_color_1"></ColorPicker>
                <div class="mt-3 fw-normal fs-6">{{$t('end_color')}}</div>
                <ColorPicker :value="background_gradient_color_2" @change="changeGradientColor2" key="gradient_color_2"></ColorPicker>
              </div>
              <div v-if="background_type === 'gradient'">
                <div class="mt-3 fw-normal fs-6">{{$t('direction')}}</div>
                <table class="w-100">
                    <tbody>
                      <tr>
                        <td class="border-0 align-middle">
                          <button type="button"
                            :class="{ 'btn btn-outline-secondary btn-gradient-direction w-100 active': background_gradient_direction === 'to top left', 'btn btn-outline-secondary btn-gradient-direction w-100': background_gradient_direction !== 'to top left' }"
                            @click="changeDirection('to top left')"><i class="bi bi-arrow-up-left"></i>
                          </button>
                        </td>
                        <td class="border-0 align-middle">
                          <button type="button" 
                          :class="{ 'btn btn-outline-secondary btn-gradient-direction w-100 active': background_gradient_direction === 'to top', 'btn btn-outline-secondary btn-gradient-direction w-100': background_gradient_direction !== 'to top' }"
                          @click="changeDirection('to top')"><i class="bi bi-arrow-up"></i></button></td>
                        <td class="border-0 align-middle">
                          <button type="button" 
                            :class="{ 'btn btn-outline-secondary btn-gradient-direction w-100 active': background_gradient_direction === 'to top right', 'btn btn-outline-secondary btn-gradient-direction w-100': background_gradient_direction !== 'to top right' }"
                            @click="changeDirection('to top right')"><i class="bi bi-arrow-up-right"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td class="border-0 align-middle">
                          <button type="button" 
                            :class="{ 'btn btn-outline-secondary btn-gradient-direction w-100 active': background_gradient_direction === 'to left', 'btn btn-outline-secondary btn-gradient-direction w-100': background_gradient_direction !== 'to left' }"
                            @click="changeDirection('to left')"><i class="bi bi-arrow-left"></i>
                          </button>
                        </td>
                        <td class="border-0 align-middle">
                          <button type="button" class="btn btn-outline-secondary btn-gradient-direction w-100 d-none" disabled></button></td>
                        <td class="border-0 align-middle">
                          <button type="button" 
                            :class="{ 'btn btn-outline-secondary btn-gradient-direction w-100 active': background_gradient_direction === 'to right', 'btn btn-outline-secondary btn-gradient-direction w-100': background_gradient_direction !== 'to right' }" 
                            @click="changeDirection('to right')"><i class="bi bi-arrow-right"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td class="border-0 align-middle">
                          <button type="button" 
                            :class="{ 'btn btn-outline-secondary btn-gradient-direction w-100 active': background_gradient_direction === 'to bottom left', 'btn btn-outline-secondary btn-gradient-direction w-100': background_gradient_direction !== 'to bottom left' }"
                            @click="changeDirection('to bottom left')"><i class="bi bi-arrow-down-left"></i>
                          </button>
                        </td>
                        <td class="border-0 align-middle">
                          <button type="button" 
                            :class="{ 'btn btn-outline-secondary btn-gradient-direction w-100 active': background_gradient_direction === 'to bottom', 'btn btn-outline-secondary btn-gradient-direction w-100': background_gradient_direction !== 'to bottom' }"
                            @click="changeDirection('to bottom')"><i class="bi bi-arrow-down"></i>
                          </button>
                        </td>
                        <td class="border-0 align-middle">
                          <button type="button" 
                            :class="{ 'btn btn-outline-secondary btn-gradient-direction w-100 active': background_gradient_direction === 'to bottom right', 'btn btn-outline-secondary btn-gradient-direction w-100': background_gradient_direction !== 'to bottom right' }"
                            @click="changeDirection('to bottom right')"><i class="bi bi-arrow-down-right"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>

          <div class="d-none"><input type="file" id="background_image_file" @change="uploadImage($event)"/></div>

          <div class="modal-footer flex-nowrap">
            <button type="button" class="btn btn-outline-secondary w-50" @click="closeModalAndUpdateCSS()">{{$t('discard')}}</button>
            <button type="button" class="btn btn-outline-primary w-50" @click="saveSettings()">{{$t('save')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import request from '@/api/request.js'
import icon_svg from "@/utils/icon_svg.js"
import utils from "@/utils/utils.js"
import predefined from "@/utils/predefined.js"
import { mapGetters } from 'vuex'
import ColorPicker from '@/views/index/components/settings/color_picker.vue'
export default {
    name: 'TitleSetting',
    props: ['local_settings'],
    components: {
        ColorPicker
    },
    data: () => ({
      icon_svg: icon_svg,
      predefined: predefined,
      background_type: 'color',
      backround_color: '',
      background_gradient_color_1: '',
      background_gradient_color_2: '',
      background_gradient_direction: 'to top right',
      background_image: '',
      upload_image_progress: false
    }),
    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        settings: 'mypage/getSettings',
        page_id: 'mypage/getPageId'
      })
    },
    created(){
    },
    methods: {
      initData(){
        this.background_type = this.local_settings.mypage_background_type
        this.backround_color = this.local_settings.mypage_background_color
        this.background_image = this.local_settings.mypage_background_image
        this.background_gradient_color_1 = this.local_settings.mypage_background_gradient_color_1
        this.background_gradient_color_2 = this.local_settings.mypage_background_gradient_color_2
        this.background_gradient_direction = this.local_settings.mypage_background_gradient_direction
        this.updatePreview()
      },
      updatePreview(){
        // const root = document.querySelector(":root");
        const root = document.getElementById("app");
        let preview_style = 'z-index:1;'
        if (this.background_type === 'color'){
          preview_style += 'background-color:' + this.backround_color + '; background-image:none;'
          root.style.setProperty("--mypage-background-color", this.backround_color );
          root.style.setProperty("--mypage-background-image", 'none' );
        }
        if (this.background_type === 'image'){
          preview_style += 'background-color:none; background-image:url("' + this.background_image + '"); background-repeat: no-repeat; background-size: cover; object-fit: cover; background-position: center center;'
          root.style.setProperty("--mypage-background-color", 'none' );
          root.style.setProperty("--mypage-background-image", 'url("' + this.background_image + '")' );
        }
        if (this.background_type === 'gradient'){
          preview_style += 'background-color:none; background-image:linear-gradient(' + this.background_gradient_direction +', ' + this.background_gradient_color_1 + ', ' + this.background_gradient_color_2 + ')'
          root.style.setProperty("--mypage-background-color", 'none' );
          root.style.setProperty("--mypage-background-image", 'linear-gradient(' + this.background_gradient_direction +', ' + this.background_gradient_color_1 + ', ' + this.background_gradient_color_2 + ')' );
        }
        document.querySelectorAll(".modal-background-setting .preview").forEach(function(item){
          item.style = preview_style
        });
      },
      closeModalAndUpdateCSS(){
        utils.updateCSSVariables(this.settings)
        bootstrap.Modal.getOrCreateInstance(document.getElementById('backgroundSettingModal')).hide()
      },
      changeBackgroundColor(color){
        this.backround_color = color
        this.updatePreview()
      },
      changeGradientColor1(color){
        this.background_gradient_color_1 = color
        this.updatePreview()
      },
      changeGradientColor2(color){
        this.background_gradient_color_2 = color
        this.updatePreview()
      },
      changeDirection(value){
        this.background_gradient_direction = value
        this.updatePreview()
      },
      performClickUpload(){
        document.getElementById('background_image_file').click()
      },
      uploadImage(event){
        var self = this
        self.upload_image_progress = true
        let formData = new FormData();
        formData.append('image_file', event.target.files[0]);
        formData.append('quality', 75);
        formData.append('max_size', 1600);
        request.upload_image( formData ).then(response => {
            if (response.code == 2000){
              var image_url = response.data
              self.background_image = image_url
            }
            document.getElementById('background_image_file').value = ''
            self.upload_image_progress = false
            self.updatePreview()
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'});
        })
      },
      saveSettings(){
        var update_data = [
          { 'key': 'mypage_background_type', 'value': this.background_type },
          { 'key': 'mypage_background_color', 'value': this.backround_color },
          { 'key': 'mypage_background_image', 'value': this.background_image },
          { 'key': 'mypage_background_gradient_color_1', 'value': this.background_gradient_color_1 },
          { 'key': 'mypage_background_gradient_color_2', 'value': this.background_gradient_color_2 },
          { 'key': 'mypage_background_gradient_direction', 'value': this.background_gradient_direction }
        ]
        this.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
        this.update_setting()
      },
      update_setting(){
        var self = this
        var postData = {
          page_id: self.page_id,
          setting: JSON.stringify(self.settings)
        }
        request.page_update_setting( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'});
                self.closeModalAndUpdateCSS()
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'});
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'});
        })
      }
    },
    watch: {
      settings(){
        this.initData()
      },
      local_settings(){
        this.initData()
      }
    }
};
</script>

<style>
.modal-background-setting .background-color,
.modal-background-setting .main-color,
.modal-background-setting .secondary-color{
  width: 2.5rem; 
  height: 2.5rem; 
  border: 1px solid #dee2e6;
}
.modal-background-setting .background-color.selected,
.modal-background-setting .main-color.selected,
.modal-background-setting .secondary-color.selected{
  border: 4px solid #FFFFFF;
  box-shadow: 0 2px 4px 4px rgb(0 0 0 / 10%);
}
.modal-background-setting .preview{
  padding: 12px 0 1px 0;
}
.modal-background-setting .preview .preview-block{
  padding: var(--mypage-block-padding);
  margin: var(--mypage-block-margin);
  border-radius: var(--mypage-block-border-radius);
  background-color: var(--mypage-block-background-color);
  border-width: var(--mypage-block-border-width);
  border-color: var(--mypage-block-border-color);
  border-style: var(--mypage-block-border-style);
}
.img-wrapper{
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.modal-background-setting .btn-gradient-direction{
  height: 2.5rem;
}
.background-setting-modal.modal-open .modal-backdrop{
  opacity: 0;
}
</style>