<template>
  <div class="blocks-wrapper">
    <draggable v-model="local_blocks" @start="drag=true" @end="drag=false" handle=".block-mask" ghost-class="ghost-block" @change="listBlockChanged()">
      <div v-for="(item, index) in local_blocks" :key="item.block_id" :data-item-order="index" >
        <component :is="'block_'+item.block_type"
          :block_index="index"
          :block_data="item"
          :key="item.block_id"
          @move_up="moveUp"
          @move_down="moveDown"
          @edit_block="editBlockModal"
          @update_block="updateBlock"
          @delete_block="confirmDeleteBlock"
        />
      </div>
    </draggable>
    <div v-if="builder_mode == true && preview_mode == false" class="d-flex justify-content-center">
      <button type="button" class="btn btn-add-block d-flex align-items-center position-fixed" @click="openChooseBlockType"> 
        <span v-html="icon_svg.bi_plus()"></span>
      </button>

      <div class="modal modal-choose-block-type" id="chooseBlockTypeModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
          <div class="modal-content choose-block-type-modal-content">
            <div class="modal-header">
              <div class="w-100 d-flex flex-row justify-content-between">
                <button type="button" class="btn btn-back d-flex align-items-center" data-bs-dismiss="modal">
                  <span v-html="icon_svg.bi_chevron_left()"></span>
                </button>
                <div class="modal-title title fs-5">{{$t('choose_block_type')}} ({{local_blocks.length}}/{{max_blocks}})</div>
                <div style="min-width: 2rem;"></div>
              </div>
            </div>
            <div class="modal-body h-100">
              <div class="position-relative" :style="[(local_blocks.length >= max_blocks) ? { 'pointer-events': 'none' } : '']">
                <div class="pb-5">
                  <div class="text-center" style="color:#003162; font-size:20px;">{{$t('basic')}}</div>
                  <div class="position-relative mt-3" @click="addBlockModal('text')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-text" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_text_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_text_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <div class="position-relative mt-3" @click="addBlockModal('image')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-image" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_image_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_image_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <div class="position-relative mt-3" @click="addBlockModal('link')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-link" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_link_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_link_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <div class="position-relative mt-3" @click="addBlockModal('divider')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-divider" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_divider_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_divider_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <!-- <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" >{{$t('text')}}</button>
                  <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('image')">{{$t('image')}}</button>
                  <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('link')">{{$t('link')}}</button>
                  <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('divider')">{{$t('divider')}}</button> -->
                  <div class="text-center mt-4" style="color:#003162; font-size:20px;">{{$t('advanced')}}</div>

                  <div class="position-relative mt-3" @click="addBlockModal('bio')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-bio" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_bio_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_bio_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <div class="position-relative mt-3" @click="addBlockModal('product')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-product" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_product_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_product_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <div class="position-relative mt-3" @click="addBlockModal('card')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-card" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_card_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_card_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 8px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <div class="position-relative mt-3" @click="addBlockModal('voucher')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 10px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-voucher" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_voucher_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_voucher_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <div class="position-relative mt-3" @click="addBlockModal('spotlight')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-spotlight" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_spotlight_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_spotlight_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <div class="position-relative mt-3" @click="addBlockModal('image_slider')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-image_slider" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_image_slider_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_image_slider_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <div class="position-relative mt-3" @click="addBlockModal('multi_links')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-multi_links" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_multi_links_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_multi_links_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>

                  <div class="position-relative mt-3" @click="addBlockModal('embedded')">
                    <div class="d-flex flex-row align-items-center block-item-select" style="border-radius: 8px; padding: 10px;">
                      <div class="me-3 d-flex align-items-center">
                        <div class="icon-wrapper">
                          <div class="icon icon-block-embedded" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                          {{$t('block_embedded_title')}}
                        </div>
                        <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                          {{$t('block_embedded_description')}}
                        </div>
                      </div>
                      <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
                        <i class="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </div>


                  <!-- <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('bio')">{{$t('bio')}}</button>
                  <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('spotlight')">{{$t('spotlight')}}</button>
                  <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('card')">{{$t('card')}}</button>
                  <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('image_slider')">{{$t('image_slider')}}</button>
                  <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('multi_links')">{{$t('multi_links')}}</button>
                  <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('product')">{{$t('product')}}</button>
                  <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('voucher')">{{$t('voucher')}}</button>
                  <button type="button" class="btn btn-primary rounded-pill w-100 mt-3" @click="addBlockModal('embedded')">{{$t('embedded')}}</button> -->
                </div>

                <div v-if="local_blocks.length >= max_blocks" class="position-absolute disable-add-block d-flex align-items-center justify-content-center">
                  <div class="fs-5 text-danger">
                    <div>{{$t('block_limit')}} ({{local_blocks.length}}/{{max_blocks}})</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal modal-confirm modal-confirm-delete-block" id="confirmDeleteBlockModal" data-bs-backdrop="static" data-bs-keyboard="false"  aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered animate__animated animate__zoomIn">
          <div class="modal-content">
            <div class="modal-header">
              <div class="fw-bold fs-6">{{$t('delete_block')}}</div>
              <button type="button" class="btn-close" aria-label="Close" @click="closeConfirmDeleteBlockModal()"></button>
            </div>
            <div class="modal-body">
              <div class="text-center fs-6">{{$t('delete_block')}}</div>
              </div>
            <div class="modal-footer flex-nowrap">
              <button type="button" class="btn btn-outline-secondary w-50" @click="closeConfirmDeleteBlockModal()">{{$t('cancel')}}</button>
              <button type="button" class="btn btn-danger w-50" @click="deleteBlock">{{$t('delete')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="builder_mode == true && preview_mode == false">
      <keep-alive>
        <component :is="edit_block"
          :block_data="block_data"
          :is_edit_block="is_edit_block"
          :block_index="block_index"
          :block_type="block_type"
          @add_block="addBlock"
          @edit_block="updateBlock"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import icon_svg from "@/utils/icon_svg.js"
const EditBlock = () => import('@/views/index/components/blocks/edit_block.vue')
import { mapGetters } from 'vuex'
import request from '@/api/request.js'
import draggable from 'vuedraggable'
export default {
    name: 'BlockWrapper',
    components: {
        'block_text': () => import('@/views/index/components/blocks/block_text.vue'),
        'block_image': () => import('@/views/index/components/blocks/block_image.vue'),
        'block_link': () => import('@/views/index/components/blocks/block_link.vue'),
        'block_embedded': () => import('@/views/index/components/blocks/block_embedded.vue'),
        'block_bio': () => import('@/views/index/components/blocks/block_bio.vue'),
        'block_product': () => import('@/views/index/components/blocks/block_product/block_product.vue'),
        'block_spotlight': () => import('@/views/index/components/blocks/block_spotlight/block_spotlight.vue'),
        'block_image_slider': () => import('@/views/index/components/blocks/block_image_slider/block_image_slider.vue'),
        'block_card': () => import('@/views/index/components/blocks/block_card/block_card.vue'),
        'block_multi_links': () => import('@/views/index/components/blocks/block_multilinks/block_multilinks.vue'),
        'block_divider': () => import('@/views/index/components/blocks/block_divider.vue'),
        'block_voucher': () => import('@/views/index/components/blocks/block_voucher/block_voucher.vue'),
        draggable
    },
    props: [],
    data: () => ({
      icon_svg: icon_svg,
      block_data: {
        block_id: -1,
        block_title: '',
        block_description: '',
        block_content: '{}',
        block_type: '',
        hide: 0
      },
      is_edit_block: false,
      block_index: -1,
      block_type: '',
      delete_block_index: -1,
      environment: '',
      max_blocks: 40,
      local_blocks: []
    }), 
    created(){
      this.environment = process.env.NODE_ENV
      if (this.environment === 'development'){
        this.max_blocks = 10
      }
      this.local_blocks = this.blocks
    },
    computed: {
        ...mapGetters({
          preview_mode: 'builder/previewMode',
          builder_mode: 'builder/builderMode',
          blocks: 'mypage/getBlocks',
          block_ids: 'mypage/getBlockIds',
          number_of_blocks: 'mypage/numberOfBlocks',
          page_id: 'mypage/getPageId'
        }),

        edit_block(){
          if (this.builder_mode == true) return EditBlock
          return null
        }
    },
    methods: {
      moveUp(block_index){
        this.$store.commit('mypage/SWAP_BLOCK', {'from': block_index, 'to': block_index - 1})
        var self = this
        var postData = {
          page_id: self.page_id,
          block_ids: this.block_ids
        }
        request.page_update_block( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'})
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'})
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'})
        })
      },
      moveDown(block_index){
        this.$store.commit('mypage/SWAP_BLOCK', {'from': block_index, 'to': block_index + 1})
        var self = this
        var postData = {
          page_id: self.page_id,
          block_ids: this.block_ids
        }
        request.page_update_block( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'})
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'})
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'})
        })
      },
      listBlockChanged(){
        this.$store.commit('mypage/SET_BLOCKS', this.local_blocks)

        var self = this
        var postData = {
          page_id: self.page_id,
          block_ids: this.block_ids
        }
        request.page_update_block( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'})
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'})
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'})
        })
      }, 
      openChooseBlockType(){
        var modal = document.getElementById('chooseBlockTypeModal')
        modal.addEventListener('show.bs.modal', () => { document.body.classList.add('choose-block-type-modal') });
        modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('choose-block-type-modal') });
        bootstrap.Modal.getOrCreateInstance(modal).show()
      },
      addBlockModal(type){
        this.modal_show = true
        this.block_index = this.number_of_blocks
        var block_data = {
              block_id: this.number_of_blocks,
              block_title: '',
              block_description: '',
              block_content: '{}',
              block_type: type,
              hide: 0
            }
        this.block_data = Object.assign({}, this.block_data, block_data)

        this.is_edit_block = false
        this.block_type = type
        var modal = document.getElementById('editBlockModal')
        var edit_block_type = 'edit-block-' + type + '-modal'
        modal.addEventListener('show.bs.modal', () => { document.body.classList.add('edit-block-modal'); document.body.classList.add(edit_block_type) });
        modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('edit-block-modal'); document.body.classList.remove(edit_block_type) });
        bootstrap.Modal.getOrCreateInstance(modal).show()
      },
      editBlockModal(index){
        this.block_index = index
        var block_data = JSON.parse(JSON.stringify(this.local_blocks[index]))
        this.block_data = Object.assign({}, this.block_data, block_data)

        this.is_edit_block = true
        this.block_type = block_data.block_type
        var modal = document.getElementById('editBlockModal')
        modal.addEventListener('show.bs.modal', () => { document.body.classList.add('edit-block-modal') });
        modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('edit-block-modal') });
        bootstrap.Modal.getOrCreateInstance(modal).show()
      },

      addBlock(payload){
        var block_index = payload.block_index
        var self = this
        var postData = {
          page_id: self.page_id,
          block_type: payload.block_type,
          block_title: payload.block_title,
          block_description: payload.block_description,
          block_content: payload.block_content,
          hide: payload.hide
        }
        request.block_add_block( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'})
                var item = {
                  'block_id': response.block_id,
                  'block_title': postData.block_title,
                  'block_description': postData.block_description,
                  'block_content': payload.block_content,
                  'block_type': postData.block_type,
                  'hide': payload.hide
                }
                self.$store.commit('mypage/ADD_BLOCK', { 'index': block_index, 'value': item})

                setTimeout(function(){
                  self.scrollToBlock(response.block_id)
                }, 200);

            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'})
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'})
        })
      },
      updateBlock(payload){
        var block_index = payload.block_index
        var self = this
        var postData = {
          block_id: payload.block
        }
        for ( var property in payload ) {
            var key = property
            var value = payload[property]
            if (key !== 'block_index'){
              postData[key] = value
            }
        }
        request.block_update_block( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'})
                var update_data = []
                for ( var property in payload ) {
                    var key = property
                    var value = payload[property]
                    if (key !== 'block_index'){
                      var item = { 'key': key, 'value': value }
                      update_data.push(item)
                    }
                }
                self.$store.commit('mypage/UPDATE_BLOCK', {'block_index': block_index, 'update_data': update_data})
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'})
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'})
        })
      },
      confirmDeleteBlock(index){
        this.delete_block_index = index
        var modal = document.getElementById('confirmDeleteBlockModal')
        modal.addEventListener('show.bs.modal', () => { document.body.classList.add('confirm-delete-block-modal') });
        modal.addEventListener('hide.bs.modal', () => { this.delete_block_index = -1; document.body.classList.remove('confirm-delete-clock-modal') });
        modal.addEventListener('hidden.bs.modal', () => {
          document.querySelector('#confirmDeleteBlockModal .modal-dialog').classList.remove('animate__zoomOut')
          document.querySelector('#confirmDeleteBlockModal .modal-dialog').classList.add('animate__zoomIn')
        });
        bootstrap.Modal.getOrCreateInstance(modal).show()
      },
      deleteBlock(){
        let index = this.delete_block_index
        var self = this
        var postData = {
          page_id: self.page_id,
          block_id: self.blocks[index].block_id
        }
        request.block_delete_block( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'})
                self.$store.commit('mypage/DELETE_BLOCK', index)
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'})
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'})
        })
        this.delete_block_index = -1;
        bootstrap.Modal.getOrCreateInstance(document.getElementById('confirmDeleteBlockModal')).hide()
      },
      closeConfirmDeleteBlockModal(){
        document.querySelector('#confirmDeleteBlockModal .modal-dialog').classList.remove('animate__zoomIn')
        document.querySelector('#confirmDeleteBlockModal .modal-dialog').classList.add('animate__zoomOut')
        setTimeout(()=>{ bootstrap.Modal.getOrCreateInstance(document.getElementById('confirmDeleteBlockModal')).hide() }, 100)
      },
      scrollToBlock(block_id){
        var element = document.querySelector('.blocks-wrapper .block-' + block_id)
        var elementPosition = element.getBoundingClientRect().top
        var offsetPosition = elementPosition + window.pageYOffset - 10
      
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
        this.blinkBackground(block_id)
      },
      blinkBackground(block_id){
        var element = document.querySelector('.blocks-wrapper .block-' + block_id)
        element.classList.add('blink-shadow')
        setTimeout(function(){
            element.classList.remove('blink-shadow')
        }, 3000);
      }
    },
    watch: {
      blocks(){
        this.local_blocks = this.blocks
      }
    }
};
</script>

<style>
.blocks-wrapper .btn.btn-add-block{
  bottom: 24px;
  padding: 0.75rem;
  line-height: 1;
  background-color: #003366;
  border-radius: 50%;
  box-shadow: 0 2px 8px 8px rgba(255,255,255,0.1), 0 4px 16px 16px rgba(255,255,255,0.1), 0 8px 32px 32px rgba(255,255,255,0.1), 0 16px 64px 64px rgba(255,255,255,0.1), 0 0 2px 0px white inset;
  border: 1px solid #FFFFFF;
  z-index: 1;
}
.blocks-wrapper .btn.btn-add-block:active,
.blocks-wrapper .btn.btn-add-block:focus{
  background-color: #003366;
  box-shadow: 0 2px 8px 8px rgb(255,255,255,0.1), 0 4px 16px 16px rgb(255,255,255,0.1) 0 0 4px 0px #003366 inset;
}
.blocks-wrapper .btn.btn-add-block .bi{
  width: 1.5rem;
  height: 1.5rem;
  color: #FFFFFF;
}
#chooseBlockTypeModal .modal-header{
  padding: 0.5rem 0;
  border-bottom: 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%);
  z-index: 1;
}
#chooseBlockTypeModal .modal-header .btn{
  height: 2rem;
  line-height: 1;
}
#chooseBlockTypeModal .modal-header .btn-back{
  padding: 0.25rem 0.5rem;
}
#chooseBlockTypeModal .modal-header .title{
  color: #003366;
}
#chooseBlockTypeModal .modal-header .bi{
  width: 1rem;
  color: #003366;
}
#chooseBlockTypeModal,
#editBlockModal{
  background-color: #F2F3F5;
}
#chooseBlockTypeModal .modal-dialog,
#editBlockModal .modal-dialog{
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  transform: translate(0);
}

#chooseBlockTypeModal .disable-add-block{
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.6);
}
@keyframes blink {
    50% {
/*        border: solid 1px #003366;*/
        box-shadow: 0 0 64px rgb(0 123 255 / 50%);
    }
}
.blink-shadow {
  animation: blink 1.0s infinite;
}
.block-item-select .icon-wrapper{
  width: 50px;
  height: 50px;
}

.block-item-select{
  box-shadow: 0 2px 4px #003162B5;
  cursor: pointer;
  background-color: #FFFFFF;
}
.block-item-select .text{
  color:#003162; 
}
.block-item-select:hover{
  background-color: #0078db;
  color: #FFFFFF;
}
.block-item-select .icon{
  background-position: center;
  background-repeat: none;
  background-size: contain;
}
.block-item-select .icon-block-text {
  background-image: url('/assets/img/builder/icon_block_text.png');
}
.block-item-select:hover .icon-block-text{
  background-image: url('/assets/img/builder/icon_block_text_2.png');
}
.block-item-select .icon-block-image {
  background-image: url('/assets/img/builder/icon_block_image.png');
}
.block-item-select:hover .icon-block-image{
  background-image: url('/assets/img/builder/icon_block_image_2.png');
}
.block-item-select .icon-block-link {
  background-image: url('/assets/img/builder/icon_block_link.png');
}
.block-item-select:hover .icon-block-link{
  background-image: url('/assets/img/builder/icon_block_link_2.png');
}
.block-item-select .icon-block-divider {
  background-image: url('/assets/img/builder/icon_block_divider.png');
}
.block-item-select:hover .icon-block-divider{
  background-image: url('/assets/img/builder/icon_block_divider_2.png');
}
.block-item-select .icon-block-bio {
  background-image: url('/assets/img/builder/icon_block_bio.png');
}
.block-item-select:hover .icon-block-bio{
  background-image: url('/assets/img/builder/icon_block_bio_2.png');
}
.block-item-select .icon-block-spotlight {
  background-image: url('/assets/img/builder/icon_block_spotlight.png');
}
.block-item-select:hover .icon-block-spotlight{
  background-image: url('/assets/img/builder/icon_block_spotlight_2.png');
}
.block-item-select .icon-block-card {
  background-image: url('/assets/img/builder/icon_block_card.png');
}
.block-item-select:hover .icon-block-card{
  background-image: url('/assets/img/builder/icon_block_card_2.png');
}
.block-item-select .icon-block-image_slider{
  background-image: url('/assets/img/builder/icon_block_image_slider.png');
}
.block-item-select:hover .icon-block-image_slider{
  background-image: url('/assets/img/builder/icon_block_image_slider_2.png');
}
.block-item-select .icon-block-multi_links{
  background-image: url('/assets/img/builder/icon_block_multi_links.png');
}
.block-item-select:hover .icon-block-multi_links{
  background-image: url('/assets/img/builder/icon_block_multi_links_2.png');
}
.block-item-select .icon-block-product{
  background-image: url('/assets/img/builder/icon_block_product.png');
}
.block-item-select:hover .icon-block-product{
  background-image: url('/assets/img/builder/icon_block_product_2.png');
}
.block-item-select .icon-block-voucher{
  background-image: url('/assets/img/builder/icon_block_voucher.png');
}
.block-item-select:hover .icon-block-voucher{
  background-image: url('/assets/img/builder/icon_block_voucher_2.png');
}
.block-item-select .icon-block-embedded{
  background-image: url('/assets/img/builder/icon_block_embedded.png');
}
.block-item-select:hover .icon-block-embedded{
  background-image: url('/assets/img/builder/icon_block_embedded_2.png');
}
.block-item-select:hover .text{
  color: #FFFFFF;
}
.block-item-select .text_1{
  font-size: 18px;
}
.block-item-select .text_2{
  font-size: 15px;
}
@media (max-width: 450px) { 
  .block-item-select .icon-wrapper{
    width: 33px;
    height: 33px;
  }
  .block-item-select .text_1{
    font-size: 13px;
  }
  .block-item-select .text_2{
    font-size: 11px;
  }
}
</style>