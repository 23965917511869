<template>
  <div>
    <div class="modal modal-setting modal-color-setting" id="colorSettingModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="colorSettingModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
        <div class="modal-content builder-modal-content modal-content-color-setting">
          <div class="modal-header" style="background-color:#FFFFFF;">
            <div class="w-100 d-flex flex-row justify-content-between">
              <button type="button" class="btn btn-back d-flex align-items-center" data-bs-dismiss="modal">
                  <span v-html="icon_svg.bi_chevron_left()"></span>
                </button>
              <div class="title fs-5 d-flex align-items-center" id="colorSettingModalLabel">{{$t('color_setting')}}</div>
              <div style="min-width: 2rem;"></div>
            </div>
          </div>
          <div class="modal-body p-0 pb-5" style="background-color:#FFFFFF; overflow-y: scroll;">
            <div class="p-3">
              <div class="mt-3 fs-6">{{$t('main_color')}}</div>
              <ColorPicker :value="main_color" @change="changeMainColor" key="main_color"></ColorPicker>
              <div class="mt-3 fs-6">{{$t('secondary_color')}}</div>
              <ColorPicker :value="secondary_color" @change="changeSecondaryColor" key="secondary_color"></ColorPicker>
            </div>
          </div>
          <div class="modal-footer flex-nowrap">
            <button type="button" class="btn btn-outline-secondary w-50" data-bs-dismiss="modal">{{$t('discard')}}</button>
            <button type="button" class="btn btn-outline-primary w-50" @click="saveSettings()">{{$t('save')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import icon_svg from "@/utils/icon_svg.js"
import utils from "@/utils/utils.js"
import predefined from "@/utils/predefined.js"
import { mapGetters } from 'vuex'
import request from '@/api/request.js'
import ColorPicker from '@/views/index/components/settings/color_picker.vue'
export default {
    name: 'ColorSetting',
    props: ['local_settings'],
    components: {
        ColorPicker
    },
    data: () => ({
      icon_svg: icon_svg,
      predefined: predefined,
      main_color: '',
      secondary_color: ''
    }),
    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        settings: 'mypage/getSettings',
        page_id: 'mypage/getPageId'
      })
    },
    created(){
      // this.initData()
    },
    methods: {
      initData(){
        this.main_color = this.local_settings.mypage_main_color
        this.secondary_color = this.local_settings.mypage_secondary_color
      },
      changeMainColor(color){
        this.main_color = color
      },
      changeSecondaryColor(color){
        this.secondary_color = color
      },
      saveSettings(){
        var update_data = [
          { 'key': 'mypage_main_color', 'value': this.main_color },
          { 'key': 'mypage_secondary_color', 'value': this.secondary_color }
        ]
        this.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
        utils.updateCSSVariables(this.settings)
        this.update_setting()
        bootstrap.Modal.getOrCreateInstance(document.getElementById('colorSettingModal')).hide()
      },
      update_setting(){
        var self = this
        var postData = {
          page_id: this.page_id,
          setting: JSON.stringify(this.settings)
        }
        request.page_update_setting( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'});
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'});
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'});
        })
      }
    },
    watch: {
      settings(){
        this.initData()
      },
      local_settings(){
        this.initData()
      }
    }
};
</script>

<style>
.color-setting-modal.modal-open .modal-backdrop{
  opacity: 0;
}
</style>