<template>
  <div>
    <div id="builder-topbar" class="fixed-top builder-topbar p-2 builder-font">
        <div class="builder-topbar-inner">
            <div class="d-flex flex-row justify-content-between">
              <div>
                <button type="button" class="btn btn-builder btn-revert d-flex align-items-center" @click="revertPublishedPage()">
                  <span class="">{{ $t('revert_published_page') }}</span>
                </button>
              </div>
              <div class="d-flex flex-row">
                <div class="dropdown-center">
                  <!-- <button type="button" class="btn btn-builder btn-intro-main me-2 d-flex align-items-center" @click="openStartTourGuideModal()">
                      <i class="bi bi-question-circle"></i>
                  </button> -->
                  <button type="button" class="btn btn-builder btn-intro-main me-2 d-flex align-items-center" id="dropdownMenuIntroMain" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                      <i class="bi bi-question-circle"></i>
                  </button>
                  <div class="dropdown-menu dropdown-intro-guide" id="dropdown-intro-guide-1" aria-labelledby="dropdownMenuIntroMain">
                    <div class="position-relative">
                      <!-- <div class="image-wrapper">
                        <img src="https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-17.gif">
                      </div> -->
                      <button class="position-absolute btn-close-intro-dropdown d-flex align-items-center justify-content-center position-relative" @click="closeDropdownIntro">
                        <i class="bi bi-x-lg"></i>
                      </button>
                    </div>
                    <div class="p-3">
                      <div class="fw-bold title text-center">
                        {{ $t('intro_start') }}
                      </div>
                      <div class="text">
                        <span v-html="$t('intro_start_description')"></span>
                      </div>
                      <button type="submit" class="btn btn-primary w-100 mt-3" @click="introJSStart()">{{ $t('start_tour') }}<span class="ms-2">→</span></button>
                    </div>
                  </div>
                </div>

                <button type="button" class="btn btn-builder btn-preview me-2 d-flex align-items-center" @click="openPreviewDesign()">
                    <span v-html="icon_svg.bi_eye()"></span>
                </button>
                <button type="button" class="btn btn-builder btn-publish d-flex align-items-center" @click="publish_page()">
                  <span v-html="icon_svg.bi_arrow_up_circle()"></span><span class="ms-1">{{ $t('publish') }}</span>
                </button>
              </div>
            </div>
        </div>
    </div>
    <div class="modal modal-publish" id="publishModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="publishModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered animate__animated animate__zoomIn">
        <div class="modal-content border-0">
          <div class="modal-header position-relative">
            <button type="button" class="btn-close btn-close-publish-modal" aria-label="Close" @click="closePublishModal()"></button>
          </div>
          <div class="modal-body">
            <div class="text-center fs-6">{{$t('publish-successfully')}}</div>
            <div class="input-group mt-3">
              <input type="text" class="form-control" :placeholder="mypage_link" aria-label="" aria-describedby="button-copy-mypage-link" disabled style="font-size:13px;">
              <button type="button" id="button-copy-mypage-link" class="btn btn-outline-secondary btn-copy" @click="copyLink()"><i class="bi bi-clipboard"></i></button>
            </div>
            <button type="button" class="btn btn-outline-primary w-100 mt-3" @click="gotoPage()">{{$t('visit_page')}}<i class="bi bi-box-arrow-up-right ms-2"></i></button>
            <button type="button" class="btn btn-outline-primary w-100 mt-3" @click="sharePage()">{{$t('share')}}<i class="bi bi-send ms-2"></i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-confirm-revert-page modal-confirm-revert-page" data-bs-backdrop="static" id="confirmRevertPageModal" data-bs-keyboard="false"  aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered animate__animated animate__zoomIn">
        <div class="modal-content border-0">
          <div class="modal-body">
            <div class="text-center fs-6">{{$t('revert_page_guide')}}</div>
          </div>
          <div class="modal-footer flex-nowrap">
            <button type="button" class="btn btn-outline-secondary w-50" @click="closeConfirmRevertPageModal()">{{$t('cancel')}}</button>
            <button type="button" class="btn btn-danger w-50" @click="confirmRevertPage()">{{$t('confirm')}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-revert-page-progress" id="revertPageProgressModal" data-bs-backdrop="static" data-bs-keyboard="false"  aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered animate__animated animate__zoomIn">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <div class="spinner-border mypage-spinner-border" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-start-tour-guide" id="startTourGuideModal" data-bs-keyboard="false" aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered animate__animated animate__zoomIn">
        <div class="modal-content border-0">
          <!-- <div class="modal-header position-relative">
            <button type="button" class="btn-close btn-close-start-tour-guide-modal" aria-label="Close" @click="closeStartTourGuideModal()"></button>
          </div> -->
          <div class="modal-body p-0">
            <div class="position-relative">
              <!-- <div class="image-wrapper">
                <img src="https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-17.gif">
              </div> -->
              <button class="position-absolute btn-close-start-tour-guide-modal d-flex align-items-center justify-content-center position-relative" @click="closeStartTourGuideModal()">
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
            <div class="p-3">
              <div class="fw-bold title text-center">
                {{ $t('intro_start') }}
              </div>
              <div class="text">
                <span v-html="$t('intro_start_description')"></span>
              </div>
              <button type="submit" class="btn btn-primary w-100 mt-3" @click="introJSStart()">{{ $t('start_tour') }}<span class="ms-2">→</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import icon_svg from "@/utils/icon_svg.js"
import utils from "@/utils/utils.js"
import predefined from "@/utils/predefined.js"
import { mapGetters } from 'vuex'
import request from '@/api/request.js'
import introJS from "intro.js/intro.js"
import 'intro.js/introjs.css'
import '@/styles/introjs-custom.css'
export default {
    name: 'BuilderTopbar',
    components: {
    },
    mounted(){
      // if (this.new_user == true){
      //   this.introJSStart()
      // }
    },
    data: () => ({
      icon_svg: icon_svg,
      predefined: predefined,
      asp_manage_domain: '',
      mypage_link: '',
      environment: ''
    }),
    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        language: 'builder/getLanguage',
        tab_settings: 'builder/tabSettings',
        settings: 'mypage/getSettings',
        page_id: 'mypage/getPageId',
        subdomain: 'mypage/getSubdomain',
        current_domain: 'mypage/getCurrentDomain',
        new_user: 'builder/getNewUser'
      })
    },
    created(){
      this.asp_manage_page = process.env.VUE_APP_ASP_MANAGE_PAGE_DOMAIN + '/manage/mypage'
      this.environment = process.env.NODE_ENV
    },
    methods: {
      openStartTourGuideModal(){
        document.getElementById('startTourGuideModal').addEventListener('show.bs.modal', () => { document.body.classList.add('publish-modal') });
        document.getElementById('startTourGuideModal').addEventListener('hide.bs.modal', () => { document.body.classList.remove('publish-modal') });
        document.getElementById('startTourGuideModal').addEventListener('hidden.bs.modal', () => {
          document.querySelector('#startTourGuideModal .modal-dialog').classList.remove('animate__zoomOut')
          document.querySelector('#startTourGuideModal .modal-dialog').classList.add('animate__zoomIn')
        });
        bootstrap.Modal.getOrCreateInstance(document.getElementById('startTourGuideModal')).show()
      },

      introJSStart(){
        var self = this
        var element = document.getElementById('dropdown-intro-guide-1')
        bootstrap.Dropdown.getOrCreateInstance(element).hide()
        // self.closeStartTourGuideModal()

        var next_button_text = '<span class="text-label-intro me-1">'+ this.$t('introjs_next_text') + '</span>'
        var intro = introJS()
        var steps_desktop = [
              {
                element: document.querySelector('#nav-1-tab'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step1_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step1_title')+'<div></div></div>',
                intro: this.$t('intro_step1_description'),
              },
              {
                element: document.querySelector('#nav-2-tab'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step2_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step2_title')+'<div></div></div>',
                intro: this.$t('intro_step2_description'),
              },
              {
                element: document.querySelector('.btn-builder.btn-revert'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step3_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step3_title')+'<div></div></div>',
                intro: this.$t('intro_step3_description'),
              },
              {
                element: document.querySelector('.btn-builder.btn-preview'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step4_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step4_title')+'<div></div></div>',
                intro: this.$t('intro_step4_description'),
              },
              {
                element: document.querySelector('.btn-builder.btn-publish'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step5_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step5_title')+'<div></div></div>',
                intro: this.$t('intro_step5_description'),
              },
              // {
              //   element: document.querySelector('.block-design .btn-edit-block .dropdown-menu'),
              //   title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step6_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step6_title')+'<div></div></div>',
              //   intro: this.$t('intro_step6_description'),
              // },
              {
                element: document.querySelector('.btn-add-block'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step7_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step7_title')+'<div></div></div>',
                intro: this.$t('intro_step7_description'),
              },
              {
                element: document.querySelector('#nav-3-tab'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step8_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step8_title')+'<div></div></div>',
                intro: this.$t('intro_step8_description'),
              },
              {
                element: document.querySelector('#nav-4-tab'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step9_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step9_title')+'<div></div></div>',
                intro: this.$t('intro_step9_description'),
              },
            ]
        var steps_mobile = [
              {
                element: document.querySelector('#nav-1-tab'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step1_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step1_title')+'<div></div></div>',
                intro: this.$t('intro_step1_description'),
                position: 'right'
              },
              {
                element: document.querySelector('#nav-2-tab'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step2_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step2_title')+'<div></div></div>',
                intro: this.$t('intro_step2_description'),
              },
              {
                element: document.querySelector('.btn-builder.btn-revert'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step3_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step3_title')+'<div></div></div>',
                intro: this.$t('intro_step3_description'),
              },
              {
                element: document.querySelector('.btn-builder.btn-preview'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step4_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step4_title')+'<div></div></div>',
                intro: this.$t('intro_step4_description'),
              },
              {
                element: document.querySelector('.btn-builder.btn-publish'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step5_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step5_title')+'<div></div></div>',
                intro: this.$t('intro_step5_description'),
              },
              // {
              //   element: document.querySelector('.block-design .btn-edit-block .dropdown-menu'),
              //   title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step6_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step6_title')+'<div></div></div>',
              //   intro: this.$t('intro_step6_description'),
              // },
              {
                element: document.querySelector('.btn-add-block'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step7_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step7_title')+'<div></div></div>',
                intro: this.$t('intro_step7_description'),
              },
              {
                element: document.querySelector('#nav-3-tab'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step8_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step8_title')+'<div></div></div>',
                intro: this.$t('intro_step8_description'),
              },
              {
                element: document.querySelector('#nav-4-tab'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step9_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step9_title')+'<div></div></div>',
                intro: this.$t('intro_step9_description'),
              },
            ]
        if (document.querySelector('.block-design .btn-edit-block .dropdown-menu')){
          var item_desktop = {
                element: document.querySelector('.block-design .btn-edit-block .dropdown-menu'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step6_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step6_title')+'<div></div></div>',
                intro: this.$t('intro_step6_description')
              }
          var item_mobile = {
                element: document.querySelector('.block-design .btn-edit-block .dropdown-menu'),
                title: '<div class="wrapper-image"><div class="top-image-fix position-absolute"></div><img class="img-product-guide" src="'+ this.$t('intro_step6_image')+'"> </div><div class="title fs-6">'+this.$t('intro_step6_title')+'<div></div></div>',
                intro: this.$t('intro_step6_description'),
                // position: 'left'
              }
          steps_desktop.splice(5, 0, item_desktop)
          steps_mobile.splice(5, 0, item_mobile)
        }
        var steps = steps_desktop
        if (window.innerWidth <= 450){
          steps = steps_mobile
        }
        intro.setOptions({
          nextLabel: next_button_text + this.$t('introjs_next'),
          prevLabel: this.$t('introjs_back'),
          doneLabel: this.$t('introjs_done'),
          skipLabel: this.$t('introjs_dismiss'),
          tooltipClass: 'tooltip-product-guide',
          hidePrev: true,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          disableInteraction: true,
          overlayOpacity: 0.2,
          showBullets: false,
          showStepNumbers:true,
          stepNumbersOfLabel: '/',
          steps: steps
        })
        intro.onbeforechange(function () {
          // if (intro._currentStep == "0") {
          //   document.querySelector('#nav-1-tab').click()
          // } 
          // if (intro._currentStep == "1") {
          //   document.querySelector('#nav-2-tab').click()
          // } 
          if (intro._currentStep == "5") {
            var element = document.querySelector('.block-design .btn-edit-block')
            if (element){
              bootstrap.Dropdown.getOrCreateInstance(element).show()
            }
          } 
          if (intro._currentStep == "6") {
            element = document.querySelector('.block-design .btn-edit-block')
            if (element){
              bootstrap.Dropdown.getOrCreateInstance(element).hide()
            }
          } 
          // if (document.querySelector('.block-design .btn-edit-block .dropdown-menu')){
          //   if (intro._currentStep == "7") {
          //     document.querySelector('#nav-3-tab').click()
          //   } 
          //   if (intro._currentStep == "8") {
          //     document.querySelector('#nav-4-tab').click()
          //   } 
          // }else{
          //   if (intro._currentStep == "6") {
          //     document.querySelector('#nav-3-tab').click()
          //   } 
          //   if (intro._currentStep == "7") {
          //     document.querySelector('#nav-4-tab').click()
          //   } 
          // }
        })
        intro.onexit(function () {
          var element = document.querySelector('.block-design .btn-edit-block')
          if (element){
            bootstrap.Dropdown.getOrCreateInstance(element).hide()
          }
          if (self.new_user == true){
            document.querySelector('#nav-1-tab').click()
          }else{
            document.querySelector('#nav-2-tab').click()
          }
        })
        intro.start()
      },
      closeDropdownIntro(e){
        var element = e.target.parentNode.parentNode.parentNode
        bootstrap.Dropdown.getOrCreateInstance(element).hide()
      },
      changeMode(value){
        this.$store.dispatch('builder/setPreviewMode', value)
      },
      openPreviewDesign(){
        this.$emit("open_preview_design_modal")
      },
      openMypageSetting(){
        this.$emit("open_mypage_setting_modal")
      },
      openThemeStore(){
        this.$emit("open_theme_store_modal")
      },
      publish_page(){
        var self = this
        var postData = {
          page_id: self.page_id
        }
        request.page_publish( postData ).then(response => {
            if (response.code === 2000) {
                self.mypage_link = self.subdomain + '.' + self.current_domain
                document.getElementById('publishModal').addEventListener('show.bs.modal', () => { document.body.classList.add('publish-modal') });
                document.getElementById('publishModal').addEventListener('hide.bs.modal', () => { document.body.classList.remove('publish-modal') });
                document.getElementById('publishModal').addEventListener('hidden.bs.modal', () => {
                  document.querySelector('#publishModal .modal-dialog').classList.remove('animate__zoomOut')
                  document.querySelector('#publishModal .modal-dialog').classList.add('animate__zoomIn')
                });
                bootstrap.Modal.getOrCreateInstance(document.getElementById('publishModal')).show()
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'})
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'})
        })
      },
      revertPublishedPage(){
        bootstrap.Modal.getOrCreateInstance(document.getElementById('confirmRevertPageModal')).hide()
        document.getElementById('confirmRevertPageModal').addEventListener('show.bs.modal', () => { document.body.classList.add('confirm-revert-page-modal') });
        document.getElementById('confirmRevertPageModal').addEventListener('hide.bs.modal', () => { document.body.classList.remove('confirm-revert-page-modal') });
        document.getElementById('confirmRevertPageModal').addEventListener('hidden.bs.modal', () => {
          document.querySelector('#confirmRevertPageModal .modal-dialog').classList.remove('animate__zoomOut')
          document.querySelector('#confirmRevertPageModal .modal-dialog').classList.add('animate__zoomIn')
        });
        bootstrap.Modal.getOrCreateInstance(document.getElementById('confirmRevertPageModal')).show()
      },
      closePublishModal(){
        document.querySelector('#publishModal .modal-dialog').classList.remove('animate__zoomIn')
        document.querySelector('#publishModal .modal-dialog').classList.add('animate__zoomOut')
        setTimeout(()=>{ bootstrap.Modal.getOrCreateInstance(document.getElementById('publishModal')).hide() }, 100)
      },
      closeStartTourGuideModal(){
        document.querySelector('#startTourGuideModal .modal-dialog').classList.remove('animate__zoomIn')
        document.querySelector('#startTourGuideModal .modal-dialog').classList.add('animate__zoomOut')
        setTimeout(()=>{ bootstrap.Modal.getOrCreateInstance(document.getElementById('startTourGuideModal')).hide() }, 100)
      },
      closeConfirmRevertPageModal(){
        document.querySelector('#confirmRevertPageModal .modal-dialog').classList.remove('animate__zoomIn')
        document.querySelector('#confirmRevertPageModal .modal-dialog').classList.add('animate__zoomOut')
        setTimeout(()=>{ bootstrap.Modal.getOrCreateInstance(document.getElementById('confirmRevertPageModal')).hide() }, 100)
      },
      confirmRevertPage(){
        bootstrap.Modal.getOrCreateInstance(document.getElementById('confirmRevertPageModal')).hide()
        document.getElementById('revertPageProgressModal').addEventListener('show.bs.modal', () => { document.body.classList.add('revert-page-progress-modal') });
        document.getElementById('revertPageProgressModal').addEventListener('hide.bs.modal', () => { document.body.classList.remove('revert-page-progress-modal') });
        bootstrap.Modal.getOrCreateInstance(document.getElementById('revertPageProgressModal')).show()

        var self = this
        var postData = {
          page_id: self.page_id
        }
        request.page_revert_published_page( postData ).then(response => {
            var update_data = []
            var setting_array = []
            var block_array = []
            var property = ''
            var key = ''
            var value = ''
            var item = {}

            if (response.code === 2000) {
              var page_data = response.data 
              setting_array = page_data.setting
              if ('block_arr' in page_data){
                  block_array = page_data.block_arr
              }

              for ( property in setting_array ) {
                  key = property
                  value = setting_array[property]
                  item = { 'key': key, 'value': value }
                  update_data.push(item)
              }
              self.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
              setTimeout(()=>{ utils.updateCSSVariables(self.settings) }, 200)
              self.$store.commit('mypage/SET_BLOCKS', block_array)

              self.$toast.open({message: response.message, type: 'success', position: 'top'})
              bootstrap.Modal.getOrCreateInstance(document.getElementById('revertPageProgressModal')).hide()
            }else{
              block_array = [] 
              setting_array = self.predefined.setting_obj()
              for ( property in setting_array ) {
                  key = property
                  value = setting_array[property]
                  item = { 'key': key, 'value': value }
                  update_data.push(item)
              }

              self.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
              setTimeout(()=>{ utils.updateCSSVariables(self.settings) }, 200)
              self.updatePageSetting()
              self.$store.commit('mypage/SET_BLOCKS', block_array)

              // self.$toast.open({message: response.error, type: 'error', position: 'top'})
              bootstrap.Modal.getOrCreateInstance(document.getElementById('revertPageProgressModal')).hide()
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'})
        })
      },
      updatePageSetting(){
        var self = this
        var postData = {
          page_id: self.page_id,
          setting: JSON.stringify(self.predefined.setting_obj())
        }
        request.page_update_setting( postData ).then(() => {
            
        }).catch(function() {
        })
      },
      copyLink(){
        var link = 'https://' + this.subdomain + '.' + this.current_domain
        this.copyToClipboard(link)
      },
      copyToClipboard(text){
        var el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '')
        el.style = {position: 'absolute', left: '-9999px'}
        document.getElementById("publishModal").appendChild(el)
        el.select()
        document.execCommand('copy')
        document.getElementById("publishModal").removeChild(el)
        this.$toast.open({message: this.$t("copied"), type: 'success', position: 'top'})
      },
      gotoPage(){
        var link = 'https://'+ this.subdomain + '.' + this.current_domain + '/'
        window.open(link, '_blank');
      },
      sharePage(){
        const link = this.subdomain + '.' + this.current_domain
        const shareData = {
          title: 'Share Mypage',
          text: link,
          url: 'https://'+link
        }
        try {
          navigator.share(shareData);
        } catch (error) {
          self.$toast.open({message: error, type: 'error', position: 'top'})
        }
      },
      exportPublishedPage(){
        var self = this
        request.page_get_published(self.subdomain).then(response =>{
          var filename = "data.json"
          var page_data = response.data
          var blocks = []
          for (var i = 0; i < page_data.block_arr.length ; i++){
            var block = page_data.block_arr[i]
            var block_content = []
            var j = 0
            var item = {}
            if ( block.block_type === 'image' || block.block_type === 'link' ){
                block.rentracks_link = ''
            }
            if (  block.block_type === 'spotlight' ||
                  block.block_type === 'multi_links' ||
                  block.block_type === 'image_slider' ||
                  block.block_type === 'voucher' ||
                  block.block_type === 'card' ) {
              block_content = JSON.parse(utils.unescapeHtml(block.block_content))
              for (j = 0; j < block_content.items.length ; j++){
                item = block_content.items[j]
                item.rentracks_link = ''
              }
              block.block_content = utils.escapeHtml(JSON.stringify(block_content))
            }
            if ( block.block_type === 'product' ) {
              block_content = JSON.parse(utils.unescapeHtml(block.block_content))
              for (j = 0; j < block_content.product.length ; j++){
                item = block_content.product[j]
                item.rentracks_link = ''
              }
              block.block_content = utils.escapeHtml(JSON.stringify(block_content))
            }
            blocks.push(block)
          }

          page_data.block_arr = []
          page_data.block_arr = blocks

          const blob = new Blob([JSON.stringify(page_data)]);

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'bottom'});
        })
      }
    },

    watch: {
        new_user(){
          console.log(this.new_user)
          if (this.new_user == true){
            // this.introJSStart()
          }
        }
    }
};
</script>

<style>
.btn-mypage-setting .dropdown-menu-dark{
  padding: 0;
  background-color: rgba(0, 0, 0, 0.9);
}
.btn-mypage-setting .dropdown-item{
  padding: 0.8rem 0.75rem;

}
.btn-mypage-setting .dropdown-item .bi{
  color: #FFFFFF;
  width: 0.9rem;
  height: 0.9rem;
}
.btn-mypage-setting .dropdown-divider{
  margin: 0;
}
.btn-mypage-setting .dropdown-menu li > button:focus{
  background-color: rgba(0, 0, 0, 0.8);
}
.btn-mypage-setting .dropdown-menu li:first-child > button:focus,
.btn-mypage-setting .dropdown-menu li:first-child > button:hover {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.btn-mypage-setting .dropdown-menu li:last-child > button:focus,
.btn-mypage-setting .dropdown-menu li:last-child > button:hover {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.builder-title{
  line-height: 1;
}
.publish-modal .modal-backdrop{

}
#publishModal .modal-content{
  max-width: 300px;
  margin: 0 auto;
  border: none;
}
#publishModal .modal-header{
  padding: 0;
  border-bottom: 0;
}
#publishModal .modal-body{
  margin-top: 2rem;
  padding-top: 0;
}
#publishModal .btn-close-publish-modal{
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.animate__animated.animate__zoomIn {
  --animate-duration: 0.2s;
}
#publishModal .modal-dialog{
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  transform: translate(0);
}

#confirmRevertPageModal .modal-dialog{
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  transform: translate(0);
}

.modal-confirm-revert-page .modal-content{
  max-width: 280px;
  margin: 0 auto;
}
.modal-revert-page-progress{
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-revert-page-progress .modal-content{
  width: auto;
  margin: 0 auto;
  border: none;
}
#button-copy-mypage-link {
  border-color: #ced4da;
}
#button-copy-mypage-link:hover{
  border-color: #6c757d;
}
.dropdown-intro-guide {
  border: none;
  box-shadow: rgb(51 51 51 / 20%) 0px 2px 8px 8px, rgb(51 51 51 / 20%) 0px 4px 16px 16px;
  min-width: 280px;
  padding: 0;
}


#startTourGuideModal .btn-close-start-tour-guide-modal{
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.modal-start-tour-guide .modal-content{
  width: 320px;
  max-width: 320px;
  border-radius: 4px;
}
.modal-start-tour-guide .image-wrapper img{
  border-radius: 4px 4px 0 0;
}

.modal-start-tour-guide .btn-close-start-tour-guide-modal{
  top: 0.5rem;
  right: 0.5rem;
  padding: 0;
  background-color: transparent;
  color: #FFF;
  border: none;
}
.modal-start-tour-guide .btn-close-start-tour-guide-modal .bi{
  color: #999;
  font-size: 1.25rem;
  line-height: 1;
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
}
</style>