<template>
  <div class="mypage-color-picker">
      <button class="btn button-picker" type="button" @click="showPicker"
      :style="'background-color:'+value+';'"
      ></button>
      <div class="offcanvas offcanvas-bottom offcanvas-color-picker">
        <div class="offcanvas-header">
          <div class="offcanvas-title fs-5">{{$t('pick_a_color')}}</div>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body pt-0">
          <div class="d-flex flex-column">
            <div class="fw-normal fs-6">{{$t('preset_color')}}</div>
            <div class="d-flex flex-wrap">
              <div v-for="(item) in predefined.predefined_colors()" v-bind:key="item" style="padding:4px;">
                <div 
                :class="{ 'presets-color selected': color == item, 'presets-color': color != item }"
                :style="'background-color:' + item + ';'" @click="changeColor(item)">
                </div>
              </div>
            </div>
            <div class="mt-3 fw-normal fs-6">{{$t('custom_color')}}</div>
            <chrome-picker v-if="show_picker" v-model="color" />
          </div>
        </div>
        <div class="offcanvas-footer p-3">
          <button class="btn btn-primary w-100" type="button" @click="selectColor">{{$t('select')}}</button>
        </div>
      </div>
    </div>
</template>

<script>
import { Chrome } from 'vue-color'
import predefined from "@/utils/predefined.js"
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import Vue from "vue"
export default {
    name: 'MyColorPicker',
    components: {
      'chrome-picker': Chrome
    },
    props: ['value'],
    data: () => ({
      color: '',
      predefined: predefined,
      button_color: '',
      show_picker: false,
      preview_style: ''
    }),
    created(){
      this.initData()
    },
    methods: {
      initData(){
        Vue.set(this, 'color', this.value)
      },
      showPicker(e){
        // console.log(this.color)
        this.show_picker = true
        var offcanvasEle = e.target.parentNode.querySelector(".offcanvas-color-picker")
        offcanvasEle.addEventListener('show.bs.offcanvas', () => { document.body.classList.add('color-picker-offcanvas') });
        offcanvasEle.addEventListener('hide.bs.offcanvas', () => { document.body.classList.remove('color-picker-offcanvas') });
        bootstrap.Offcanvas.getOrCreateInstance(offcanvasEle).show()
      },
      changeColor(color){
        this.color = color
      },
      selectColor(e){
        if (typeof this.color === 'object' && 'hex8' in this.color){
          this.button_color = this.color.hex8
          this.$emit('change', this.color.hex8)
        }else{
          this.button_color = this.color
          this.$emit('change', this.color)
        }
        this.show_picker = false
        var offcanvasEle = e.target.parentNode.parentNode
        bootstrap.Offcanvas.getOrCreateInstance(offcanvasEle).hide()
      }
    },
    watch: {
      value(){
        this.initData()
      }
    }
};
</script>

<style>
.vc-chrome{
  width: 100%!important;
  box-shadow: none!important;
}
.mypage-color-picker .offcanvas-color-picker{
  max-width: var(--max-width-custom);
  margin: auto;
  height: auto;
  border-radius: 8px 8px 0px 0px;
}
.mypage-color-picker .button-picker{
  width: 3rem;
  height: 3rem;
  box-shadow: 0 1px 3px 3px rgb(0 0 0 / 10%);
}
.mypage-color-picker .presets-color{
  width: 2rem; 
  height: 2rem; 
  border: 1px solid #dee2e6;
  cursor: pointer;
}
.offcanvas-color-picker .presets-color.selected,
.offcanvas-color-picker .presets-color.selected{
  border: 4px solid #FFFFFF;
  box-shadow: 0 2px 4px 4px rgb(0 0 0 / 10%);
}
.offcanvas-color-picker{
  border-top: none!important;
}
.color-picker-offcanvas .modal-backdrop{
  z-index:21;
}
</style>