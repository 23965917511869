import http from './http-config';
import Cookies from 'js-cookie'
export default {
	// builder 
	get_authorization_token(token){
		const timestamp = Date.now()
		return http.get('/mypage/user/exchange_token?token=' + token + '&t=' + timestamp)
	},
	page_list() {
		const timestamp = Date.now()
		const token = Cookies.get('token')
		return http.get('/mypage/page/list?t=' + timestamp, {'headers': {'Authorization': token}})
	},
	page_get(page_id, token = '') {
		const timestamp = Date.now()
		if (token === ''){
			token = Cookies.get('token')
		}
		return http.get('/mypage/page/get?page_id=' + page_id+ '&t=' + timestamp, {'headers': {'Authorization': token}})
	},
	page_get_sample(data_id, token = '') {
		const timestamp = Date.now()
		if (token === ''){
			token = Cookies.get('token')
		}
		return http.get('/mypage/page/preview?template_name=' + data_id+ '&t=' + timestamp, {'headers': {'Authorization': token}})
	},
	page_update_setting(postData) {
		const token = Cookies.get('token')
		return http.post('/mypage/page/update_setting', postData, {'headers': {'Authorization': token}})
	},
	page_revert_published_page(postData) {
		const token = Cookies.get('token')
		return http.post('/mypage/page/revert', postData, {'headers': {'Authorization': token}})
	},
	page_update_block(postData) {
		const token = Cookies.get('token')
		return http.post('mypage/page/update_block', postData, {'headers': {'Authorization': token}})
	},
	page_publish(postData) {
		const token = Cookies.get('token')
		return http.post('/mypage/page/publish', postData, {'headers': {'Authorization': token}})
	},
	page_fill_sample_data(postData) {
		const token = Cookies.get('token')
		return http.post('/mypage/page/fill_sample_data', postData, {'headers': {'Authorization': token}})
	},
	page_setting(postData) {
		const token = Cookies.get('token')
		return http.post('mypage/page/setting', postData, {'headers': {'Authorization': token}})
	},
	page_check_subdomain(data) {
		const token = Cookies.get('token')
		return http.get('mypage/page/check_sub_domain?page_id=' + data.page_id + '&subdomain=' + data.subdomain, {'headers': {'Authorization': token}})
	},
	block_add_block(postData) {
		const token = Cookies.get('token')
		return http.post('/mypage/block/add_block', postData, {'headers': {'Authorization': token}})
	},
	block_update_block(postData) {
		const token = Cookies.get('token')
		return http.post('/mypage/block/update', postData, {'headers': {'Authorization': token}})
	},
	block_delete_block(postData) {
		const token = Cookies.get('token')
		return http.post('/mypage/block/delete_block', postData, {'headers': {'Authorization': token}})
	},
	make_affiliate_link(postData) {
		const token = Cookies.get('token')
		const controller = new AbortController();
		setTimeout(()=>{ 
            controller.abort()
        }, 10000)
		return http.post('/mypage/block/make_affiliate_link', postData, {signal: controller.signal, timeout: 10000, 'headers': {'Authorization': token}})
	},
	upload_image(formData) {
		const token = Cookies.get('token')
		return http.post('/mypage/image/upload', formData, {'headers': {'Content-Type': 'multipart/form-data', 'Authorization': token}})
	},
	
	// client
	page_get_published(subdomain) {
		const timestamp = Date.now()
		return http.get('/mypage/page/get_published?subdomain=' + subdomain + '&t=' + timestamp)
	}
}
