<template>
  <div class="w-100 h-100" style="padding: 24px 24px 32px 24px; background-color:#FFFFFF;">
      <div style="display: grid; grid-template-columns: repeat(1, minmax(0px, 1fr)); gap: 12px;">
        <!-- <button type="button" class="btn btn-primary btn-lg rounded-pill fs-6" @click="openSettingColor()">
            {{$t('color')}}
        </button> -->
        <!-- <button type="button" class="btn btn-primary btn-lg rounded-pill fs-6" @click="openSettingBackground()">
            {{$t('background')}}
        </button>
        <button type="button" class="btn btn-primary btn-lg rounded-pill fs-6" @click="openSettingBlock()">
            {{$t('block')}}
        </button>
        <button type="button" class="btn btn-primary btn-lg rounded-pill fs-6" @click="openSettingTitle()">
            {{$t('title')}}
        </button>
        <button type="button" class="btn btn-primary btn-lg rounded-pill fs-6" @click="openSettingParagraph()">
            {{$t('paragraph')}}
        </button>
        <button type="button" class="btn btn-primary btn-lg rounded-pill fs-6" @click="openSettingButton()">
            {{$t('button')}}
        </button> -->
        <div class="position-relative mt-2" @click="openSettingBackground()">
          <div class="d-flex flex-row align-items-center setting-item-select" style="border-radius: 8px; padding: 10px;">
            <div class="me-3 d-flex align-items-center">
              <div class="icon-wrapper">
                <div class="icon icon-setting-background" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center flex-grow-1">
              <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                {{$t('setting_background_title')}}
              </div>
              <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                {{$t('setting_background_description')}}
              </div>
            </div>
            <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
        </div>

        <div class="position-relative mt-2" @click="openSettingBlock()">
          <div class="d-flex flex-row align-items-center setting-item-select" style="border-radius: 8px; padding: 10px;">
            <div class="me-3 d-flex align-items-center">
              <div class="icon-wrapper">
                <div class="icon icon-setting-block" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center flex-grow-1">
              <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                {{$t('setting_block_title')}}
              </div>
              <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                {{$t('setting_block_description')}}
              </div>
            </div>
            <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
        </div>

        <div class="position-relative mt-2" @click="openSettingTitle()">
          <div class="d-flex flex-row align-items-center setting-item-select" style="border-radius: 8px; padding: 10px;">
            <div class="me-3 d-flex align-items-center">
              <div class="icon-wrapper">
                <div class="icon icon-setting-title" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center flex-grow-1">
              <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                {{$t('setting_title_title')}}
              </div>
              <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                {{$t('setting_title_description')}}
              </div>
            </div>
            <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
        </div>

        <div class="position-relative mt-2" @click="openSettingParagraph()">
          <div class="d-flex flex-row align-items-center setting-item-select" style="border-radius: 8px; padding: 10px;">
            <div class="me-3 d-flex align-items-center">
              <div class="icon-wrapper">
                <div class="icon icon-setting-paragraph" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center flex-grow-1">
              <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                {{$t('setting_paragraph_title')}}
              </div>
              <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                {{$t('setting_paragraph_description')}}
              </div>
            </div>
            <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
        </div>

        <div class="position-relative mt-2" @click="openSettingButton()">
          <div class="d-flex flex-row align-items-center setting-item-select" style="border-radius: 8px; padding: 10px;">
            <div class="me-3 d-flex align-items-center">
              <div class="icon-wrapper">
                <div class="icon icon-setting-button" style="width:100%; height:100%; object-fit: cover; display: block; border-radius: 50%;"></div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center flex-grow-1">
              <div class="text text_1" style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden; text-overflow: ellipsis; line-height:1.2; font-weight: 700;">
                {{$t('setting_button_title')}}
              </div>
              <div style="margin-top:4px; line-height:1.1;" class="text text_2">
                {{$t('setting_button_description')}}
              </div>
            </div>
            <div class="text d-flex justify-content-center align-items-center" style="width: 25px; font-size: 1.2rem;" >
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
        </div>


      </div>
      <ColorSetting :local_settings="local_settings"/>
      <BackgroundSetting :local_settings="local_settings"/>
      <BlockSetting :local_settings="local_settings"/>
      <TitleSetting :local_settings="local_settings"/>
      <ParagraphSetting :local_settings="local_settings"/>
      <ButtonSetting :local_settings="local_settings"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import ColorSetting from '@/views/index/components/settings/color_setting.vue'
import BackgroundSetting from '@/views/index/components/settings/background_setting.vue'
import BlockSetting from '@/views/index/components/settings/block_setting.vue'
import TitleSetting from '@/views/index/components/settings/title_setting.vue'
import ParagraphSetting from'@/views/index/components/settings/paragraph_setting.vue'
import ButtonSetting from '@/views/index/components/settings/button_setting.vue'

export default {
    name: 'SettingWrapper',
    components: {
      ColorSetting, BackgroundSetting, BlockSetting, TitleSetting, ParagraphSetting, ButtonSetting, 
    },
    props: [],
    data: () => ({
      local_settings: [],
    }),
    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        settings: 'mypage/getSettings',
        blocks: 'mypage/getBlocks',
        block_ids: 'mypage/getBlockIds',
        page_id: 'mypage/getPageId',
        subdomain: 'mypage/getSubdomain',
        current_domain: 'mypage/getCurrentDomain'
      }),
     
    },
    created(){
      this.icon = this.value
      this.button_icon = this.icon
    },
    methods: {

      openSettingColor(){
          this.local_settings = JSON.parse(JSON.stringify(this.settings))
          var modal = document.getElementById('colorSettingModal')
          modal.addEventListener('show.bs.modal', () => { document.body.classList.add('color-setting-modal') });
          modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('color-setting-modal') });
          bootstrap.Modal.getOrCreateInstance(modal).show()
      },
      openSettingBackground(){
          this.local_settings = JSON.parse(JSON.stringify(this.settings))
          var modal = document.getElementById('backgroundSettingModal')
          modal.addEventListener('show.bs.modal', () => { document.body.classList.add('background-setting-modal') });
          modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('background-setting-modal') });
          bootstrap.Modal.getOrCreateInstance(modal).show()
      },
      openSettingBlock(){
          this.local_settings = JSON.parse(JSON.stringify(this.settings))
          var modal = document.getElementById('blockSettingModal')
          modal.addEventListener('show.bs.modal', () => { document.body.classList.add('block-setting-modal') });
          modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('block-setting-modal') });
          bootstrap.Modal.getOrCreateInstance(modal).show()
      },
      openSettingTitle(){
          this.local_settings = JSON.parse(JSON.stringify(this.settings))
          var modal = document.getElementById('titleSettingModal')
          modal.addEventListener('show.bs.modal', () => { document.body.classList.add('title-setting-modal') });
          modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('title-setting-modal') });
          bootstrap.Modal.getOrCreateInstance(modal).show()

      },
      openSettingParagraph(){
          this.local_settings = JSON.parse(JSON.stringify(this.settings))
          var modal = document.getElementById('paragraphSettingModal')
          modal.addEventListener('show.bs.modal', () => { document.body.classList.add('paragraph-setting-modal') });
          modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('paragraph-setting-modal') });
          bootstrap.Modal.getOrCreateInstance(modal).show()
      },
      openSettingButton(){
          this.local_settings = JSON.parse(JSON.stringify(this.settings))
          var modal = document.getElementById('buttonSettingModal')
          modal.addEventListener('show.bs.modal', () => { document.body.classList.add('button-setting-modal') });
          modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('button-setting-modal') });
          bootstrap.Modal.getOrCreateInstance(modal).show()
      }
    },
    watch: {
    }
};
</script>

<style>
.setting-item-select .icon-wrapper{
  width: 50px;
  height: 50px;
}
.setting-item-select{
  box-shadow: 0 2px 4px #003162B5;
  cursor: pointer;
  background-color: #FFFFFF;
}
.setting-item-select .text{
  color:#003162; 
}
.setting-item-select:hover{
  background-color: #0078db;
  color: #FFFFFF;
}
.setting-item-select .icon{
  background-position: center;
  background-repeat: none;
  background-size: contain;
}
.setting-item-select .icon-setting-background {
  background-image: url('/assets/img/builder/icon_setting_background.png');
}
.setting-item-select:hover .icon-setting-background{
  background-image: url('/assets/img/builder/icon_setting_background_2.png');
}
.setting-item-select .icon-setting-block {
  background-image: url('/assets/img/builder/icon_setting_block.png');
}
.setting-item-select:hover .icon-setting-block{
  background-image: url('/assets/img/builder/icon_setting_block_2.png');
}
.setting-item-select .icon-setting-paragraph {
  background-image: url('/assets/img/builder/icon_setting_paragraph.png');
}
.setting-item-select:hover .icon-setting-paragraph{
  background-image: url('/assets/img/builder/icon_setting_paragraph_2.png');
}
.setting-item-select .icon-setting-title {
  background-image: url('/assets/img/builder/icon_setting_title.png');
}
.setting-item-select:hover .icon-setting-title{
  background-image: url('/assets/img/builder/icon_setting_title_2.png');
}
.setting-item-select .icon-setting-button {
  background-image: url('/assets/img/builder/icon_setting_button.png');
}
.setting-item-select:hover .icon-setting-button{
  background-image: url('/assets/img/builder/icon_setting_button_2.png');
}
.setting-item-select:hover .text{
  color: #FFFFFF;
}
.setting-item-select .text_1{
  font-size: 18px;
}
.setting-item-select .text_2{
  font-size: 15px;
}
@media (max-width: 450px) { 
  .setting-item-select .icon-wrapper{
    width: 33px;
    height: 33px;
  }
  .setting-item-select .text_1{
    font-size: 13px;
  }
  .setting-item-select .text_2{
    font-size: 11px;
  }
}
</style>