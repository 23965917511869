<template>
    <div class="mypage">
        <div v-if="builder_mode == true" class="builder h-100">
            <div class="h-100 d-flex flex-column builder-font">
                <div class="fixed-top bg-white" style="box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%);">
                    <nav id="nav-tab-mypage" style="max-width: var(--max-width-custom); margin: 0 auto;" class="d-flex flex-wrap nav-tab-mypage-wrapper">
                        <a :href="asp_manage_page" class="btn btn-back-to-manage-page d-flex align-items-center">
                            <img src="/assets/img/rentracks-logo.png" style="width:120px;" @load="updateBuilderTopbarStyle()"> 
                        </a>
                        <div class="nav nav-pills flex-fill d-flex" id="nav-tab-mypage" role="tablist">
                            <button 
                                :class="{ 'flex-fill nav-link h-100 rounded-0 active': tab_settings === 'nav-1', 'flex-fill nav-link h-100 rounded-0': tab_settings !== 'nav-1' }"
                                id="nav-1-tab" data-bs-toggle="pill" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="true">{{ $t('themes') }}</button>
                            <button 
                                :class="{ 'flex-fill nav-link h-100 rounded-0 active': tab_settings === 'nav-2', 'flex-fill nav-link h-100 rounded-0': tab_settings !== 'nav-2' }"
                                id="nav-2-tab" data-bs-toggle="pill" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">{{ $t('design_page') }}</button>
                            <button 
                                class="flex-fill nav-link h-100 rounded-0" id="nav-3-tab" data-bs-toggle="pill" data-bs-target="#nav-3" type="button" role="tab" aria-controls="nav-3" aria-selected="false">{{ $t('general_page_settings') }}</button>
                            <button class="flex-fill nav-link h-100 rounded-0" id="nav-4-tab" data-bs-toggle="pill" data-bs-target="#nav-4" type="button" role="tab" aria-controls="nav-4" aria-selected="false">{{ $t('page_settings') }}</button>
                        </div>
                    </nav>
                </div>
                <div id="mypage-nav-tabContent" class="tab-content flex-grow-1" style="background-color: #f2f3f5;">
                    <div 
                        :class="{ 'tab-pane nav-tab-pane h-100 fade show active': tab_settings === 'nav-1', 'tab-pane nav-tab-pane h-100 fade': tab_settings !== 'nav-1' }"
                        id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab" tabindex="0">
                        <div class="tab-pane-inner" style="max-width: var(--max-width-custom); margin: 0 auto; background-color:#FFFFFF; height:100%;">
                            <ThemeStore/>
                        </div>
                    </div>
                    <div 
                        :class="{ 'position-relative tab-pane nav-tab-pane h-100 fade show active': tab_settings === 'nav-2', 'position-relative tab-pane nav-tab-pane h-100 fade': tab_settings !== 'nav-2' }" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab" tabindex="0">
                        <div class="tab-pane-inner lp-container" :style="[(builder_mode == true && show_spinner == false && preview_mode == false) ? { 'padding': '48px 0 150px 0' } : { 'padding': '60px 0 0 0' }]">
                            <BuilderTopbar
                                @open_preview_design_modal="openPreviewDesignModal()"
                            />
                            <div v-if="show_spinner == false" class="mypage-wrapper" style="padding-top: 20px;">
                                <BlockWrapper/>
                            </div>
                        </div>
                        <!-- <div class="d-flex justify-content-center position-fixed w-100 btn-add-block-wrapper">
                            <button type="button" class="btn btn-add-block d-flex align-items-center" @click="openChooseBlockType">
                                <span v-html="icon_svg.bi_plus()"></span>
                            </button>
                        </div> -->
                        <div v-if="show_spinner == true" class="text-center">
                            <div class="spinner-border mypage-spinner-border" role="status">
                                <span class="visually-hidden"></span>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane nav-tab-pane h-100 fade" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab" tabindex="0">
                        <div class="tab-pane-inner" style="max-width: var(--max-width-custom); margin: 0 auto; height:100%;">
                            <SettingWrapper/>
                        </div>
                    </div>
                    <div class="tab-pane nav-tab-pane h-100 fade" id="nav-4" role="tabpanel" aria-labelledby="nav-4-tab" tabindex="0">
                        <div class="tab-pane-inner" style="max-width: var(--max-width-custom); margin: 0 auto; height:100%;">
                            <MypageSetting/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal modal-preview-design" id="previewDesignModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
              <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <div class="w-100 d-flex flex-row justify-content-between" style="max-width: var(--max-width-custom); margin: 0 auto;">
                      <button type="button" class="btn btn-back d-flex align-items-center" @click="closePreviewDesignModal()" style="min-width: 2rem;">
                          <span v-html="icon_svg.bi_chevron_left()"></span>
                      </button>
                      <div class="title d-flex align-items-center">
                          {{ $t('preview_mode') }}
                      </div>
                      <div class="d-flex flex-row" style="min-width: 2rem;">
                      </div>
                    </div>
                  </div>
                  <div class="modal-body p-0 h-100">
                    <div v-if="show_spinner_preview == true" class="text-center mt-3">
                      <div class="spinner-border mypage-spinner-border" role="status">
                        <span class="visually-hidden"></span>
                      </div>
                    </div>
                    <iframe id="preview-design-iframe" class="w-100 h-100" :src="preview_design_link" @load="updateView()"></iframe>
                  </div>         
                </div>
              </div>
            </div>
        </div>
        <div v-else>
            <div class="lp-container">
                <div v-if="show_spinner == false" class="mypage-wrapper">
                    <BlockWrapper/>
                </div>
                <div v-if="show_spinner == true" class="text-center">
                  <div class="spinner-border mypage-spinner-border" role="status" style="border-width: 0.2rem; color: #d0d4d9;">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
            </div>
        </div>
        <!-- Footer -->
        <div v-if="builder_mode == false || preview_mode == true" class="copyright" style="color:var(--mypage-paragraph-color);">
          © {{ (new Date()).getFullYear() }} - Rentracks
        </div>
    </div>
</template>

<script> 
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import Cookies from 'js-cookie'
import request from '@/api/request.js'
import router from '@/router'
import utils from "@/utils/utils.js"
import predefined from "@/utils/predefined.js"
import icon_svg from "@/utils/icon_svg.js"
import { mapGetters } from 'vuex'
import BuilderTopbar from '@/views/index/components/builder_topbar.vue'
import BlockWrapper from '@/views/index/components/blocks/block_wrapper.vue'
import SettingWrapper from '@/views/index/components/settings/setting_wrapper.vue'
import ThemeStore from '@/views/index/components/theme_store.vue'
import MypageSetting from '@/views/index/components/settings/mypage_setting.vue'

export default {
    name: 'Index',
    components: {
        BuilderTopbar, 
        BlockWrapper, 
        SettingWrapper,
        ThemeStore,
        MypageSetting
    },
    mounted(){
        this.mypage_domain = location.host.split('.')[0]
        this.$store.dispatch('builder/setBuilderMode', this.mypage_domain)
        this.new_user = this.$route.query.new_user
        if (this.new_user === 'true'){
            this.$store.dispatch('builder/setTabSettings', 'nav-1')
            this.$store.dispatch('builder/setNewUser', true) 
        }
        if (this.new_user === 'false'){
            this.$store.dispatch('builder/setTabSettings', 'nav-2')
            this.$store.dispatch('builder/setNewUser', false)
        }

        this.exchange_token = this.$route.query.token
        if (this.builder_mode == true){
            if (this.exchange_token && this.exchange_token !== ''){
                this.get_authorization_token(this.exchange_token)
            }else{
                if (Cookies.get('token')){
                    this.load_data_builder()
                }else{  
                    setTimeout(() => {  window.location.replace(process.env.VUE_APP_ASP_MANAGE_PAGE_DOMAIN + "/manage/mypage") }, 0);
                }
            }
            window.addEventListener('resize', this.updateBuilderTopbarStyle);
        }else{
            this.load_client_page()
        }

        const tabEls = document.querySelectorAll('#nav-tab-mypage button[data-bs-toggle="pill"]')
        for (let tabEl of tabEls) {
            tabEl.addEventListener('shown.bs.tab', () => {
                var tab_pane = document.querySelectorAll('#mypage-nav-tabContent .nav-tab-pane')
                for (let tab of tab_pane) {
                    if (tab.classList.contains('active')){
                        this.$store.dispatch('builder/setTabSettings', tab.id)
                    }
                    if (tab.id === 'nav-1' || tab.id === 'nav-3' || tab.id === 'nav-4'){
                        window.scrollTo(0, 0)
                    }
                }
            })
        }
        bootstrap.Tooltip.getOrCreateInstance(document.body, {
          selector: "[data-bs-toggle='tooltip']",
          trigger: 'click'
        })
        
    },

    destroyed() {
        if (this.builder_mode == true){
            window.removeEventListener("resize", this.updateBuilderTopbarStyle)
        }
    },

    created(){
        this.asp_manage_page = process.env.VUE_APP_ASP_MANAGE_PAGE_DOMAIN + '/manage/mypage'
    },

    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        tab_settings: 'builder/tabSettings',
        settings: 'mypage/getSettings',
        blocks: 'mypage/getBlocks',
        block_ids: 'mypage/getBlockIds',
        page_id: 'mypage/getPageId',
        subdomain: 'mypage/getSubdomain',
        current_domain: 'mypage/getCurrentDomain'
      }),
     
    },
    data: () => ({
        language: 'en',
        mypage_domain: '',
        show_spinner: false,
        local_blocks: [],
        local_settings: [],
        exchange_token: '',
        predefined: predefined,
        icon_svg: icon_svg,
        preview_design_link: '',
        show_spinner_preview: false,
        new_user: '',
        asp_manage_page: ''
    }),
    methods: {
        updateBuilderTopbarStyle(){
            var nav_tab = document.querySelector('.nav-tab-mypage-wrapper')
            var nav_tab_height = nav_tab.offsetHeight
            document.getElementById('builder-topbar').style = 'margin-top:' + nav_tab_height + 'px;'
            document.getElementById('mypage-nav-tabContent').style = 'margin-top:' + nav_tab_height + 'px;'
        },
        get_authorization_token(token){
            var self = this
            self.show_spinner == true
            request.get_authorization_token( token ).then(response => {
                if (response.code == 2000) {
                    Cookies.set('token', response.token)
                    Cookies.set('page_id', response.page_id)
                    self.$store.commit('builder/SET_LANGUAGE', self.language)
                    self.$i18n.locale = self.language
                    window.history.replaceState({}, '', window.location.origin)
                    self.load_data_builder()
                }else{
                    self.$toast.open({message: response.message, type: 'error', position: 'top'});
                    if (!self.mypage_domain.includes("localhost")){
                      setTimeout(() => {  window.location.replace(process.env.VUE_APP_ASP_MANAGE_PAGE_DOMAIN + "/manage/mypage") }, 0);
                    }
                }
                self.show_spinner = false
            }).catch(function (error) {
                self.$toast.open({message: error, type: 'error', position: 'top'});
                if (!self.mypage_domain.includes("localhost")){
                  setTimeout(() => {  window.location.replace(process.env.VUE_APP_ASP_MANAGE_PAGE_DOMAIN + "/manage/mypage") }, 0);
                }
            })
        },
        load_data_builder(){
            this.show_spinner = true
            this.mypage_domain = location.host.split('.')[0]
            this.$store.dispatch('builder/setBuilderMode', this.mypage_domain)
            var self = this
            request.page_list().then(response => {
                if (response.code === 2000) {
                    var page_list = response.data
                    self.load_page_content(page_list[0].page_id)
                }else{
                    self.$toast.open({message: response.message, type: 'error', position: 'top'});
                }
                self.show_spinner = false
            }).catch(function () {
                router.push('/404')
            })
        },
        load_page_content(page_id){
            var self = this
            self.show_spinner = true
            request.page_get(page_id).then(response => {
                if (response.code === 2000) {
                    var page_data = response.data
                    self.language = page_data.language
                    const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
                    if (!locales.keys().includes("./" + self.language + ".json")) {
                      self.language = 'en'
                    }
                    self.$store.commit('builder/SET_LANGUAGE', self.language)
                    self.$i18n.locale = self.language
                    self.$store.commit('mypage/SET_PAGE_ID', page_data.page_id)
                    var setting_string = page_data.setting
                    var update_data = []
                    var setting_keys = Object.keys(predefined.setting_obj())
                    var setting_array = self.predefined.setting_obj()
                    try {
                        var current_setting_array = JSON.parse(setting_string)
                        for ( var property in current_setting_array ) {
                            if (setting_keys.includes(property)){
                              setting_array[property] = current_setting_array[property]
                            }
                        }
                    } catch(e) {
                        console.log(e)
                    }
                    for ( var prop in setting_array ) {
                        if (setting_keys.includes(prop)){
                          update_data.push( { 'key': prop, 'value': setting_array[prop] })
                        }
                    }
                    self.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
                    utils.updateCSSVariables(self.settings)
                    self.$store.commit('mypage/SET_BLOCK_IDS', page_data.block_ids)
                    self.$store.commit('mypage/SET_SUBDOMAIN', page_data.subdomain)
                    self.$store.commit('mypage/SET_CURRENT_DOMAIN', page_data.current_domain)
                    self.$store.commit('mypage/SET_DOMAIN_LIST', page_data.domain_array)
                    self.$store.commit('mypage/SET_PAGE_NAME', page_data.page_name)
                    self.$store.commit('mypage/SET_PAGE_DESCRIPTION', page_data.page_description)
                    self.$store.commit('mypage/SET_PAGE_FAVICON', page_data.page_icon)

                    var block_array = [] 
                    if ('block_arr' in page_data){
                        block_array = page_data.block_arr
                    }
                    self.$store.commit('mypage/SET_BLOCKS', block_array)
                }else{
                    self.$toast.open({message: response.message, type: 'error', position: 'top'});
                }
                self.show_spinner = false
            }).catch(function (error) {
                self.$toast.open({message: error, type: 'error', position: 'top'});
            })
        },
        load_client_page(){
            var self = this
            var preview = self.$route.query.preview_mode
            var token = self.$route.query.token
            var page_id = self.$route.query.page_id
            var theme_id = self.$route.query.theme_id
            var data_id = self.$route.query.data_id
            if ( preview === 'true' ){
                if (data_id){
                    this.load_preview_theme_with_sample_data(theme_id, token)
                }else{
                    this.load_preview_theme(page_id, theme_id, token)
                }
            }else{
                this.load_published()
            }
        },
        load_preview_theme(page_id, theme_id, token){
            var self = this
            self.show_spinner = true
            self.$store.commit('builder/SET_LANGUAGE', self.language)
            self.$i18n.locale = self.language
            request.page_get(page_id, token).then(response => {
                if (response.code === 2000) {
                    var page_data = response.data
                    self.language = page_data.language
                    const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
                    if (!locales.keys().includes("./" + self.language + ".json")) {
                      self.language = 'en'
                    }
                    self.$store.commit('builder/SET_LANGUAGE', self.language)
                    self.$i18n.locale = self.language
                    self.$store.commit('mypage/SET_PAGE_ID', '')
                    var setting_string = page_data.setting
                    var update_data = []
                    var setting_keys = Object.keys(predefined.setting_obj())
                    var setting_array = {}
                    if ( theme_id in self.predefined.themes()){
                        setting_array = self.predefined.themes()[theme_id]
                    }else{
                        setting_array = self.predefined.setting_obj()
                        try {
                            var current_setting_array = JSON.parse(setting_string)
                            for ( var property in current_setting_array ) {
                                if (setting_keys.includes(property)){
                                  setting_array[property] = current_setting_array[property]
                                }
                            }
                        } catch(e) {
                            console.log(e)
                        }
                    }
                    for ( var prop in setting_array ) {
                        if (setting_keys.includes(prop)){
                          update_data.push( { 'key': prop, 'value': setting_array[prop] })
                        }
                    }
                    self.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
                    utils.updateCSSVariables(self.settings)
                    self.$store.commit('mypage/SET_BLOCK_IDS', page_data.block_ids)
                    self.$store.commit('mypage/SET_SUBDOMAIN', page_data.subdomain)
                    self.$store.commit('mypage/SET_CURRENT_DOMAIN', page_data.current_domain)

                    var block_array = [] 
                    if ('block_arr' in page_data){
                        block_array = page_data.block_arr
                    }
                    self.$store.commit('mypage/SET_BLOCKS', block_array)
                }else{
                    self.$toast.open({message: response.message, type: 'error', position: 'top'});
                }
                self.show_spinner = false
            }).catch(function (error) {
                self.$toast.open({message: error, type: 'error', position: 'top'});
            })
        },
        load_preview_theme_with_sample_data(theme_id, token){
            var data_id = theme_id
            var self = this
            self.show_spinner = true
            self.$store.commit('builder/SET_LANGUAGE', self.language)
            self.$i18n.locale = self.language
            request.page_get_sample(data_id, token).then(response => {
                var page_data = response
                self.language = page_data.language
                const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
                if (!locales.keys().includes("./" + self.language + ".json")) {
                  self.language = 'en'
                }
                self.$store.commit('builder/SET_LANGUAGE', self.language)
                self.$i18n.locale = self.language
                self.$store.commit('mypage/SET_PAGE_ID', page_data.page_id)
                var update_data = []
                var setting_keys = Object.keys(predefined.setting_obj())
                var setting_array = {}
                if ( theme_id in self.predefined.themes()){
                    setting_array = self.predefined.themes()[theme_id]
                }else{
                    setting_array = self.predefined.setting_obj()
                }
                for ( var prop in setting_array ) {
                    if (setting_keys.includes(prop)){
                      update_data.push( { 'key': prop, 'value': setting_array[prop] })
                    }
                }
                self.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
                utils.updateCSSVariables(self.settings)
                self.$store.commit('mypage/SET_BLOCK_IDS', page_data.block_ids)
                self.$store.commit('mypage/SET_SUBDOMAIN', page_data.subdomain)
                self.$store.commit('mypage/SET_CURRENT_DOMAIN', page_data.current_domain)

                var block_array = [] 
                if ('block_arr' in page_data){
                    block_array = page_data.block_arr
                }
                self.$store.commit('mypage/SET_BLOCKS', block_array)
                self.show_spinner = false
            }).catch(function (error) {
                self.$toast.open({message: error, type: 'error', position: 'top'});
            })
        },
        load_published(){
            var self = this
            self.show_spinner = true
            request.page_get_published(self.mypage_domain).then(response => {
                if (response.code === 2000) {
                    if (response.data != null){
                        var page_data = response.data
                        self.language = page_data.language
                        const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
                        if (!locales.keys().includes("./" + self.language + ".json")) {
                            self.language = 'en'
                        }
                        self.$store.commit('builder/SET_LANGUAGE', self.language)
                        self.$i18n.locale = self.language
                        var update_data = []
                        var setting_array = {}
                        setting_array = page_data.setting
                        for ( var property in setting_array ) {
                            var key = property
                            var value = setting_array[property]
                            var item = { 'key': key, 'value': value }
                            update_data.push(item)
                        }
                        self.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
                        utils.updateCSSVariables(self.settings)
                        self.$store.commit('mypage/SET_BLOCK_IDS', page_data.block_ids)
                        self.$store.commit('mypage/SET_SUBDOMAIN', page_data.subdomain)
                        self.$store.commit('mypage/SET_CURRENT_DOMAIN', page_data.current_domain)
                        var block_array = [] 
                        if ('block_arr' in page_data){
                            block_array = page_data.block_arr
                        }
                        self.$store.commit('mypage/SET_BLOCKS', block_array)
                        // self.addGTM()
                    }else{
                        router.push('/404')
                    }
                }else{
                    router.push('/404')
                }
                self.show_spinner = false
            }).catch(function (error) {
                self.$toast.open({message: error, type: 'error', position: 'top'});
            })
        },

        addGTM(){
            var script = document.createElement('script');
            script.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NHBM4CR');";
            document.getElementsByTagName('head')[0].appendChild(script);
        },

        openSettingColor(){
            this.local_settings = JSON.parse(JSON.stringify(this.settings))
            var modal = document.getElementById('colorSettingModal')
            modal.addEventListener('show.bs.modal', () => { document.body.classList.add('color-setting-modal') });
            modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('color-setting-modal') });
            bootstrap.Modal.getOrCreateInstance(modal).show()
        },
        openSettingBackground(){
            this.local_settings = JSON.parse(JSON.stringify(this.settings))
            var modal = document.getElementById('backgroundSettingModal')
            modal.addEventListener('show.bs.modal', () => { document.body.classList.add('background-setting-modal') });
            modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('background-setting-modal') });
            bootstrap.Modal.getOrCreateInstance(modal).show()
        },
        openSettingBlock(){
            this.local_settings = JSON.parse(JSON.stringify(this.settings))
            var modal = document.getElementById('blockSettingModal')
            modal.addEventListener('show.bs.modal', () => { document.body.classList.add('block-setting-modal') });
            modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('block-setting-modal') });
            bootstrap.Modal.getOrCreateInstance(modal).show()
        },
        openSettingTitle(){
            this.local_settings = JSON.parse(JSON.stringify(this.settings))
            var modal = document.getElementById('titleSettingModal')
            modal.addEventListener('show.bs.modal', () => { document.body.classList.add('title-setting-modal') });
            modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('title-setting-modal') });
            bootstrap.Modal.getOrCreateInstance(modal).show()

        },
        openSettingParagraph(){
            this.local_settings = JSON.parse(JSON.stringify(this.settings))
            var modal = document.getElementById('paragraphSettingModal')
            modal.addEventListener('show.bs.modal', () => { document.body.classList.add('paragraph-setting-modal') });
            modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('paragraph-setting-modal') });
            bootstrap.Modal.getOrCreateInstance(modal).show()
        },
        openSettingButton(){
            this.local_settings = JSON.parse(JSON.stringify(this.settings))
            var modal = document.getElementById('buttonSettingModal')
            modal.addEventListener('show.bs.modal', () => { document.body.classList.add('button-setting-modal') });
            modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('button-setting-modal') });
            bootstrap.Modal.getOrCreateInstance(modal).show()
        },

        openPreviewDesignModal(){
            document.body.classList.add('disable-scroll')
            this.preview_design_link = ''
            document.getElementById('preview-design-iframe').classList.add('d-none')
            this.show_spinner_preview = true
            const timestamp = Date.now()
            this.preview_design_link = 'https://'+ this.subdomain + '.' + this.current_domain + '/?_' + timestamp + '#/?preview_mode=true'+'&token='+Cookies.get('token')+'&page_id='+this.page_id

            var modal = document.getElementById('previewDesignModal')
            modal.addEventListener('show.bs.modal', () => { document.body.classList.add('preview-design-modal') });
            modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('preview-design-modal') });
            bootstrap.Modal.getOrCreateInstance(modal).show()
        },
        updateView(){
            document.getElementById('preview-design-iframe').classList.remove('d-none')
            this.show_spinner_preview = false
        },
        closePreviewDesignModal(){
            document.body.classList.remove('disable-scroll')
            var modal = document.getElementById('previewDesignModal')
            bootstrap.Modal.getOrCreateInstance(modal).hide()
        },

        // openChooseBlockType(){
        //     var modal = document.getElementById('chooseBlockTypeModal')
        //     modal.addEventListener('show.bs.modal', () => { document.body.classList.add('choose-block-type-modal') });
        //     modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('choose-block-type-modal') });
        //     bootstrap.Modal.getOrCreateInstance(modal).show()
        // }
    },
    watch: {
        blocks: {
            handler(){
            },
            deep: true
        },
        tab_settings() {
          if (this.tab_settings === 'nav-1' || this.tab_settings === 'nav-3' || this.tab_settings === 'nav-4'){
            document.documentElement.style = 'height:100%;'
          }else{
            document.documentElement.style = 'height:auto;'
          }
        }
    }
};
</script>

<style>
#previewDesignModal .modal-header{
  padding: 0.5rem 0;
  border-bottom: 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%);
  z-index: 11;
}
#previewDesignModal .modal-header .btn{
  height: 2rem;
  line-height: 1;
}
#previewDesignModal .modal-header .title{
  color: #003366;
}
#previewDesignModal .modal-header .bi{
  width: 1rem;
  color: #003366;
}
#previewDesignModal .modal-body{
  overflow-y: hidden;
}
#previewDesignModal .btn-back{
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}

#mypageSettingsModal .modal-header{
  padding: 0.5rem 0;
  border-bottom: 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%);
  z-index: 11;
}
#mypageSettingsModal .modal-header .btn{
  height: 2rem;
  line-height: 1;
}
#mypageSettingsModal .modal-header .title{
  color: #003366;
}
#mypageSettingsModal .modal-header .bi{
  width: 1rem;
  color: #003366;
}
#mypageSettingsModal .modal-body{
  overflow-y: hidden;
}
#mypageSettingsModal .btn-back{
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}
#mypageSettingsModal .modal-content{
  background-color: transparent;
}

#themeStoreModal .modal-header{
  padding: 0.5rem 0;
  border-bottom: 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%);
  z-index: 11;
}
#themeStoreModal .modal-header .btn{
  height: 2rem;
  line-height: 1;
}
#themeStoreModal .modal-header .title{
  color: #003366;
}
#themeStoreModal .modal-header .bi{
  width: 1rem;
  color: #003366;
}
#themeStoreModal .modal-body{
  overflow-y: hidden;
}
#themeStoreModal .btn-back{
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}
/*.btn-add-block-wrapper{
    bottom:0;
    padding-bottom: 24px;
}
.btn-add-block-wrapper .btn.btn-add-block{
  padding: 0.75rem;
  line-height: 1;
  background-color: #003366;
  border-radius: 50%;
  box-shadow: 0 2px 8px 8px rgba(255,255,255,0.1), 0 4px 16px 16px rgba(255,255,255,0.1), 0 8px 32px 32px rgba(255,255,255,0.1), 0 16px 64px 64px rgba(255,255,255,0.1), 0 0 2px 0px white inset;
  border: 1px solid #FFFFFF;
}
.btn-add-block-wrapper .btn.btn-add-block:active,
.btn-add-block-wrapper .btn.btn-add-block:focus{
  background-color: #003366;
  box-shadow: 0 2px 8px 8px rgb(255,255,255,0.1), 0 4px 16px 16px rgb(255,255,255,0.1) 0 0 4px 0px #003366 inset;
}
.btn-add-block-wrapper .btn.btn-add-block .bi{
  width: 1.5rem;
  height: 1.5rem;
  color: #FFFFFF;
}*/
</style>