<template>
  <div>
    <div class="modal modal-setting modal-paragraph-setting" id="paragraphSettingModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="paragraphSettingModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
        <div class="modal-content builder-modal-content modal-paragraph-block-setting">
          <div class="modal-header" style="background-color:#FFFFFF;">
            <div class="w-100 d-flex flex-row justify-content-between">
              <button type="button" class="btn btn-back d-flex align-items-center" data-bs-dismiss="modal">
                <span v-html="icon_svg.bi_chevron_left()"></span>
              </button>
              <div class="title fs-5 d-flex align-items-center" id="paragraphSettingModalLabel">{{$t('paragraph_setting')}}</div>
              <div style="min-width: 2rem;"></div>
            </div>
          </div>
          <div class="modal-body p-0 pb-5" style="background-color:#FFFFFF; overflow-y: scroll;">
            <div class="preview sticky-top" style="z-index:1;">
              <div class="preview-block">
                <table>
                  <tbody>
                    <tr>
                      <td class="border-0 align-middle"><div class="pe-3 text-black-50" style="font-size:12px;">Lead</div></td>
                      <td class="border-0 align-middle"><div :style="'font-size:' + font_size*ratio + 'px; font-family:' + font_family + ';line-height:' + line_height + ';font-weight:' + font_weight + ';color:' + this.color +';'">This is a paragraph.</div></td>
                    </tr>
                    <tr>
                      <td class="border-0 align-middle"><div class="pe-3 text-black-50" style="font-size:12px;">Body</div></td>
                      <td class="border-0 align-middle"><div :style="'font-size:'+font_size + 'px;font-family:' + font_family + ';line-height:' + line_height + ';font-weight:' + font_weight + ';color:' + this.color +';'">This is a paragraph.</div></td>
                    </tr>
                    <tr>
                      <td class="border-0 align-middle"><div class="pe-3 text-black-50" style="font-size:12px;">Small</div></td>
                      <td class="border-0 align-middle"><div :style="'font-size:'+font_size/ratio + 'px; font-family:' + font_family + ';line-height:' + line_height + ';font-weight:' + font_weight + ';color:' + this.color +';'">This is a paragraph.</div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="p-3">
              <div class="fw-bold fs-6">{{$t('font')}}</div>
              <div class="mt-3 fw-normal fs-6">{{$t('font')}}</div>
              <select class="form-select" aria-label=".form-select" v-model="font_family">
                <option v-for="(item,index) in predefined.predefined_fonts()" v-bind:key="index">{{item}}</option>
              </select>
            </div>
            <div class="p-3">
              <div class="fw-bold fs-6">{{$t('text_color')}}</div>
              <div class="mt-3 fw-normal fs-6">{{$t('text_color')}}</div>
              <ColorPicker :value="color" @change="changeColor" key="paragraph_color"></ColorPicker>
            </div>
            <div class="p-3">
              <div class="fw-bold fs-6">{{$t('text_setting')}}</div>
              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('font_size')}}</div>
                <div class="text-primary fw-semibold">{{font_size}} px</div>
              </div>
              <input type="range" class="form-range" min="13" max="20" step="1" v-model="font_size" id="paragraph_font_size">
              <div class="mt-3 fw-normal fs-6">{{$t('ratio')}}</div>
              <select class="form-select mb-3" aria-label=".form-select example" v-model="ratio">
                <option v-for="(item,index) in predefined.predefined_font_size_ratio()" v-bind:key="index" :value="item.value">{{item.key}}</option>
              </select>
              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('font_weight')}}</div>
                <div class="text-primary fw-semibold">{{font_weight}}</div>
              </div>
              <input type="range" class="form-range" min="400" max="900" step="100" v-model="font_weight" id="paragraph_font_weight">
              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('line_height')}}</div>
                <div class="text-primary fw-semibold">{{line_height}}</div>
              </div>
              <input type="range" class="form-range" min="1" max="2" step="0.1" v-model="line_height" id="paragraph_line_height">
            </div>
          </div>
          <div class="modal-footer flex-nowrap">
            <button type="button" class="btn btn-outline-secondary w-50" data-bs-dismiss="modal">{{$t('discard')}}</button>
            <button type="button" class="btn btn-outline-primary w-50" @click="saveSettings()">{{$t('save')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import icon_svg from "@/utils/icon_svg.js"
import utils from "@/utils/utils.js"
import request from '@/api/request.js'
import predefined from "@/utils/predefined.js"
import { mapGetters } from 'vuex'
import ColorPicker from '@/views/index/components/settings/color_picker.vue'
export default {
    name: 'ParagraphSetting',
    components: {
        ColorPicker
    },
    props: ['local_settings'],
    data: () => ({
      icon_svg: icon_svg,
      font_size: 13,
      font_weight: 500,
      font_family: '',
      line_height: 1,
      ratio: 1.2,
      color: "",
      predefined: predefined,
    }),
    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        settings: 'mypage/getSettings',
        page_id: 'mypage/getPageId'
      })
    },
    created(){
      // this.initData()
    },
    methods: {
      initData(){
        this.font_size = this.local_settings.paragraph_font_size
        this.font_weight = this.local_settings.paragraph_font_weight
        this.line_height = this.local_settings.paragraph_line_height
        this.ratio = this.local_settings.paragraph_ratio
        this.font_family = this.local_settings.paragraph_font_family
        this.color = this.local_settings.paragraph_color
      },
      changeColor(color){
        this.color = color
      },
      saveSettings(){
        var update_data = [
          { 'key': 'paragraph_font_size', 'value': this.font_size },
          { 'key': 'paragraph_font_weight', 'value': this.font_weight },
          { 'key': 'paragraph_line_height', 'value': this.line_height },
          { 'key': 'paragraph_ratio', 'value': this.ratio },
          { 'key': 'paragraph_font_family', 'value': this.font_family },
          { 'key': 'paragraph_color', 'value': this.color }
        ]
        this.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
        utils.updateCSSVariables(this.settings)
        this.update_setting()
        bootstrap.Modal.getOrCreateInstance(document.getElementById('paragraphSettingModal')).hide()
      },
      update_setting(){
        var self = this
        var postData = {
          page_id: this.page_id,
          setting: JSON.stringify(this.settings)
        }
        request.page_update_setting( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'});
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'});
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'});
        })
      }
    },
    watch: {
      settings(){
        this.initData()
      },
      local_settings(){
        this.initData()
      }
    }
};
</script>

<style>
.modal-paragraph-setting .paragraph-color{
  width: 2.5rem; 
  height: 2.5rem; 
  border: 1px solid #dee2e6;
}
.modal-paragraph-setting .paragraph-color.selected,
.modal-paragraph-setting .paragraph-color.selected{
  border: 4px solid #FFFFFF;
  box-shadow: 0 2px 4px 4px rgb(0 0 0 / 10%);
}

.modal-paragraph-setting .preview{
  overflow-x: hidden;
  padding: 12px 0;
  background-color: var(--mypage-background-color);
  background-image: var(--mypage-background-image);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center center;
}
.modal-paragraph-setting .preview-block{
  padding: var(--mypage-block-padding);
  margin: var(--mypage-block-margin);
  border-radius: var(--mypage-block-border-radius);
  background-color: var(--mypage-block-background-color);
  border-width: var(--mypage-block-border-width);
  border-color: var(--mypage-block-border-color);
  border-style: var(--mypage-block-border-style);
}
.paragraph-setting-modal.modal-open .modal-backdrop{
  opacity: 0;
}
</style>