<template>
  <div>
    <div class="modal modal-setting modal-block-setting" id="blockSettingModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="blockSettingModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
        <div class="modal-content builder-modal-content modal-content-block-setting">
          <div class="modal-header" style="background-color:#FFFFFF;">
            <div class="w-100 d-flex flex-row justify-content-between">
              <button type="button" class="btn btn-back d-flex align-items-center" data-bs-dismiss="modal">
                <span v-html="icon_svg.bi_chevron_left()"></span>
              </button>
              <div class="title fs-5 d-flex align-items-center" id="blockSettingModalLabel">{{$t('block_setting')}}</div>
              <div style="min-width: 2rem;"></div>
            </div>
          </div>
          <div class="modal-body p-0 pb-5"  style="background-color:#FFFFFF; overflow-y: scroll;">
            <div class="preview sticky-top" style="z-index:1;">
              <div class="preview-block">
                <div style="width: 100%; height: 80px; background-color: #DDDDDD;">
                </div>
              </div>
              <div class="preview-block">
                <div style="width: 100%; height: 80px; background-color: #DDDDDD;">
                </div>
              </div>
            </div>
            <div class="p-3">
              <div class="fw-bold fs-6">{{$t('block_style')}}</div>
              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('border_radius')}}</div>
                <div class="text-primary fw-semibold">{{block_border_radius}} px</div>
              </div>
              <input type="range" class="form-range" min="0" max="48" step="2" v-model="block_border_radius" id="block_border_radius" @change="updatePreview()">
              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('block_padding')}}</div>
                <div class="text-primary fw-semibold">{{block_padding}} px</div>
              </div>
              <input type="range" class="form-range" min="0" max="24" step="4" v-model="block_padding" id="block_padding" @change="updatePreview()">

              <div class="mt-3 fw-normal fs-6">{{$t('block_outside_spacing')}}</div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" v-model="block_margin_x_switch" id="block_margin_x_switch" @change="changeBlockMaginX()">
              </div>
              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('block_between_spacing')}}</div>
                <div class="text-primary fw-semibold">{{block_margin_bottom}} px</div>
              </div>
              <input type="range" class="form-range" min="12" max="24" step="4" v-model="block_margin_bottom" id="block_margin_bottom" @change="updatePreview()">
            </div>
            <div class="p-3">
              <div class="fw-bold fs-6">{{$t('background_color')}}</div>
              <div class="mt-3 fw-normal fs-6">{{$t('background_color')}}</div>
              <ColorPicker :value="block_background_color" @change="changeBlockBackgroundColor" key="block_background_color"></ColorPicker>
            </div>
            <div class="p-3">
              <div class="fw-bold fs-6">{{$t('border_setting')}}</div>
              <div class="mt-3 fw-normal fs-6">{{$t('border_color')}}</div>
              <ColorPicker :value="block_border_color" @change="changeBlockBorderColor" key="block_border_color"></ColorPicker>
              <div class="mt-3 fw-normal fs-6">{{$t('border_style')}}</div>
              <div class="mt-2">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="block_border_style" v-model="block_border_style" id="block_border_style_1" value="solid" @change="updatePreview()">
                  <label class="form-check-label" for="block_border_style_1">
                    {{$t('solid')}}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="block_border_style" v-model="block_border_style" id="block_border_style_2" value="dotted" @change="updatePreview()">
                  <label class="form-check-label" for="block_border_style_2">
                    {{$t('dotted')}}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="block_border_style" v-model="block_border_style" id="block_border_style_3" value="dashed" @change="updatePreview()">
                  <label class="form-check-label" for="block_border_style_3">
                    {{$t('dashed')}}
                  </label>
                </div>
              </div>
              <div class="mt-3 fw-normal fs-6 d-flex justify-content-between">
                <div>{{$t('border_width')}}</div>
                <div class="text-primary fw-semibold">{{block_border_width}} px</div>
              </div>
              <input type="range" class="form-range" min="0" max="12" step="1" v-model="block_border_width" id="block_border_width" @change="updatePreview()">
            </div>
            <div class="p-3">
              <div class="fw-bold fs-6">{{$t('shadow')}}</div>
              <div class="mt-3 fw-normal fs-6">{{$t('shadow')}}</div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" v-model="block_shadow" id="block_shadow" @change="changeBlockShadow()">
              </div>
              <div v-if="block_shadow == true">
                <div class="mt-3 fw-normal fs-6">{{$t('shadow')}}</div>
                <ColorPicker :value="block_shadow_color" @change="changeBlockShadowColor" key="block_shadow_color"></ColorPicker>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-nowrap">
            <button type="button" class="btn btn-outline-secondary w-50" data-bs-dismiss="modal">{{$t('discard')}}</button>
            <button type="button" class="btn btn-outline-primary w-50" @click="saveSetting()">{{$t('save')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import icon_svg from "@/utils/icon_svg.js"
import utils from "@/utils/utils.js"
import request from '@/api/request.js'
import predefined from "@/utils/predefined.js"
import { mapGetters } from 'vuex'
import ColorPicker from '@/views/index/components/settings/color_picker.vue'

export default {
    name: 'BlockSetting',
    props: ['local_settings'],
    components: {
        ColorPicker
    },
    data: () => ({
      icon_svg: icon_svg,
      predefined: predefined,
      block_border_radius: 8,
      block_padding: 12,
      block_margin_bottom: 12,
      block_border_width: 0,
      block_background_color: '',
      block_border_style: 'solid',
      block_border_color: '',
      block_margin_x_switch: true,
      block_margin_x: '12',
      block_shadow: false,
      block_shadow_color: "",
    }),
    created(){
      // this.initData()
    },
    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        settings: 'mypage/getSettings',
        page_id: 'mypage/getPageId'
      })
    },
    methods: {
      initData(){
        this.block_border_radius = this.local_settings.block_border_radius
        this.block_padding = this.local_settings.block_padding
        this.block_margin_bottom = this.local_settings.block_margin_bottom
        this.block_border_width = this.local_settings.block_border_width
        if (this.local_settings.block_margin_x && this.local_settings.block_margin_x == 0){
          this.block_margin_x_switch = false
        }else{
          this.block_margin_x_switch = true
        }
        this.block_border_color = this.local_settings.block_border_color
        this.block_border_style = this.local_settings.block_border_style
        this.block_background_color = this.local_settings.block_background_color
        if (this.local_settings.block_shadow && this.local_settings.block_shadow == 1){
          this.block_shadow = true
        }else{
          this.block_shadow = false
        }
        this.block_shadow_color = this.local_settings.block_shadow_color
        this.updatePreview()
      },
      updatePreview(){
        let preview_style =  
            'margin:0 ' + this.block_margin_x + 'px ' + this.block_margin_bottom + 'px ' + this.block_margin_x + 'px;' +
            'padding:' + this.block_padding + 'px;'+
            'border-radius:' + this.block_border_radius + 'px;'+
            'background-color:' + this.block_background_color + ';' +
            'border-color:' + this.block_border_color + ';' +
            'border-style:' + this.block_border_style + ';' +
            'border-width:' + this.block_border_width + 'px;'
        if (this.block_shadow){
          preview_style += 'box-shadow: 0 1px 2px ' + this.block_shadow_color + ';'
        }
        document.querySelectorAll(".modal-block-setting .preview .preview-block").forEach(function(item){
          item.style = preview_style
        });
      },
      changeBlockBackgroundColor(color){
        this.block_background_color = color
        this.updatePreview()
      },
      changeBlockBorderColor(color){
        this.block_border_color = color
        this.updatePreview()
      },
      changeBlockMaginX(){
        if (this.block_margin_x_switch == true){
          this.block_margin_x = 12
        }else{
          this.block_margin_x = 0
        }
        this.updatePreview()
      },
      changeBlockShadow(){
        this.updatePreview()
      },
      changeBlockShadowColor(color){
        this.block_shadow_color = color
        this.updatePreview()
      },
      saveSetting(){
        var update_data = [
          { 'key': 'block_background_color', 'value': this.block_background_color },
          { 'key': 'block_border_radius', 'value': this.block_border_radius },
          { 'key': 'block_border_width', 'value': this.block_border_width },
          { 'key': 'block_border_style', 'value': this.block_border_style },
          { 'key': 'block_border_color', 'value': this.block_border_color },
          { 'key': 'block_padding', 'value': this.block_padding },
          { 'key': 'block_margin_x', 'value': this.block_margin_x },
          { 'key': 'block_margin_bottom', 'value': this.block_margin_bottom },
          { 'key': 'block_shadow_color', 'value': this.block_shadow_color }
        ]
        if (this.block_shadow == true){
          update_data.push({ 'key': 'block_shadow', 'value': 1 })
        }else{
          update_data.push({ 'key': 'block_shadow', 'value': 0 })
        }
        this.$store.commit('mypage/UPDATE_SETTINGS', {'update_data': update_data})
        utils.updateCSSVariables(this.settings)
        this.update_setting()
        bootstrap.Modal.getOrCreateInstance(document.getElementById('blockSettingModal')).hide()
      },
      update_setting(){
        var self = this
        var postData = {
          page_id: this.page_id,
          setting: JSON.stringify(this.settings)
        }
        request.page_update_setting( postData ).then(response => {
            if (response.code === 2000) {
                self.$toast.open({message: response.message, type: 'success', position: 'top'});
            }else{
                self.$toast.open({message: response.error, type: 'error', position: 'top'});
            }
        }).catch(function (error) {
            self.$toast.open({message: error, type: 'error', position: 'top'});
        })
      }
    },
    watch: {
      settings(){
        this.initData()
      },
      local_settings(){
        this.initData()
      }
    }
};
</script>

<style>
.modal-block-setting .block-background-color,
.modal-block-setting .block-border-color{
  width: 2.5rem; 
  height: 2.5rem; 
  border: 1px solid #dee2e6;
}
.modal-block-setting .block-background-color.selected,
.modal-block-setting .block-border-color.selected{
  border: 4px solid #FFFFFF;
  box-shadow: 0 2px 4px 4px rgb(0 0 0 / 10%);
}
.modal-block-setting .preview{
  padding: 12px 0 1px 0;
  background-color: var(--mypage-background-color);
  background-image: var(--mypage-background-image);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center center;
}
.block-setting-modal.modal-open .modal-backdrop{
  opacity: 0;
}
</style>